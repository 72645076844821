/* eslint-disable */
import { FaBell, FaMoon, FaCaretDown } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '../../../Avatar';
import DialogMenu from '../../../DialogMenu/DialogMenu';
import ModalLogin from '../../../../views/auth/ModalLogin';
import NavbarRoute, { NavbarLoggedInRoute } from '../../../../routes/Navbar.route';
import TippyMenu from '../../../TippyMenu/TippyMenu';
import modalAction from '../../../../redux/modal/modal.action';
import Popover from '../../../Popover/Popover';
import LogOutModal from '../../../../views/auth/ModalLogout';
import envConstants from '../../../../config/key';
import { getHighestRole } from '../../../../utils/common';
import { USER_ROLE } from '../../../../config/constant/common.constant';

const regexLoaithiep = /^\/(loai-thiep)\//;

const NavbarDefault = () => {
    // const { pathname } = useLocation();
    const pathname = window.location.pathname;
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [loggedIn, setLoggedIn] = useState(false);
    const [userRole, setUserRole] = useState();
    const [isOpenTippyMenu, setIsOpenTippyMenu] = useState(false);
    // const [isActiveLoginTab, setIsActiveLoginTab] = useState(true)
    // const [isOpenModalLogin, setIsOpenModalLogin] = useState(false)

    // popper
    const [isOpenPopper, setIsOpenPopper] = useState(false);

    const showLoginForm = (e) => {
        e.preventDefault();
        dispatch(modalAction.openModalLogin());
        // setIsOpenModalLogin(true)
        // setIsActiveLoginTab(true)
    };

    const showRegisterForm = (e) => {
        e.preventDefault();
        dispatch(modalAction.openModalSignUp());
    };

    // const toggleModalLogin = () => {
    //     setIsOpenModalLogin(!isOpenModalLogin)
    // }

    const onOpenTippyMenu = () => {
        setIsOpenTippyMenu(true);
    };

    const onCloseTippyMenu = () => {
        setIsOpenTippyMenu(false);
    };

    useEffect(() => {
        if (auth) {
            setLoggedIn(auth.loggedIn);
            setUserRole(getHighestRole(auth?.user?.roles));
        }
    }, [auth]);

    const [colorNav, setcolorNav] = useState(false);
    useEffect(() => {
        setIsOpenTippyMenu(false);
        if (pathname !== '/') {
            setcolorNav(true);
        } else {
            setcolorNav(false);
        }
    }, [pathname]);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const header = document.querySelector('header')?.offsetHeight;
            if (window.scrollY >= header || pathname !== '/') {
                setcolorNav(true);
            } else {
                setcolorNav(false);
            }
        });
    }, [pathname]);

    return (
        <>
            <header className="fixed inset-x-0 top-0 z-[99]">
                <LogOutModal />
                <div id="headlessui-portal-root">
                    <TippyMenu
                        loggedIn={loggedIn}
                        user={auth?.user}
                        visible={isOpenTippyMenu}
                        onClose={onCloseTippyMenu}
                        showLoginForm={showLoginForm}
                        showRegisterForm={showRegisterForm}
                        userRole={userRole}
                    />
                </div>
                <div>
                    <DialogMenu visible={false} onClose={() => {}} />
                </div>
                {/* <div className="sticky top-0 z-[99] w-full backdrop-blur flex-none transition-colors duration-500  lg:border-b lg:border-slate-900/10  "> */}
                <div
                    className={`${
                        colorNav
                            ? 'bg-white supports-backdrop-blur:bg-white/60 backdrop-blur  lg:border-b lg:border-slate-900/10 shadow-md'
                            : 'bg-transparent text-white'
                    }  sticky top-0 z-[99] w-full  flex-none transition-colors duration-500  `}
                >
                    <div className="max-w-screen-xl mx-auto">
                        <div className="px-4 py-2 mx-4 border-b border-slate-900/10 lg:border-0 lg:mx-0">
                            <div className="relative flex items-center">
                                <Link to="/" className="mr-3 flex-none w-[4.8rem] md:w-auto">
                                    <span className="sr-only">HomePage</span>
                                    <img
                                        // src={envConstants.CLIENT_URL + `${userRole === USER_ROLE.VIP ? 'logo_chungvui_vip.png' : 'logo_chungvui.png'}`}
                                        src={envConstants.CLIENT_URL + 'logo_chungvui.png'}
                                        className={`w-[4rem] h-auto ${
                                            userRole !== USER_ROLE.VIP && ' my-2 '
                                        }`}
                                    />
                                    {/* <h1 className="text-2xl font-extrabold text-main-500">ChungVui</h1> */}
                                </Link>
                                {/* <button
                                type="button"
                                className="flex items-center justify-center w-8 h-8 ml-auto -my-1 text-slate-500 hover:text-slate-600 lg:hidden"
                            >
                                <span className="sr-only">Tìm kiếm</span>
                                <svg
                                    width="24"
                                    height="24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    aria-hidden="true"
                                >
                                    <path d="m19 19-3.5-3.5"></path>
                                    <circle cx="11" cy="11" r="6"></circle>
                                </svg>
                            </button> */}
                                <div className="ml-auto -my-1 lg:hidden">
                                    <button
                                        type="button"
                                        className="flex items-center justify-center w-8 h-8 text-slate-500 hover:text-slate-600 x"
                                        onClick={onOpenTippyMenu}
                                    >
                                        <span className="sr-only">Menu</span>
                                        <svg width="24" height="24" fill="none" aria-hidden="true">
                                            <path
                                                d="M12 6v.01M12 12v.01M12 18v.01M12 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="relative items-center hidden ml-auto lg:flex">
                                    <nav className="text-sm font-semibold leading-6 text-slate-700 ">
                                        <ul className="flex space-x-2 text-black">
                                            {(loggedIn ? NavbarLoggedInRoute : NavbarRoute).map(
                                                (navbar) => {
                                                    if (navbar.element) {
                                                        const Element = navbar.element;
                                                        let isActive = false;
                                                        if (
                                                            regexLoaithiep.test(navbar.path) &&
                                                            regexLoaithiep.test(pathname)
                                                        ) {
                                                            isActive = true;
                                                        } else if (navbar.path === pathname) {
                                                            isActive = true;
                                                        }
                                                        return (
                                                            <li
                                                                className={`${
                                                                    isActive &&
                                                                    'text-white bg-main-500'
                                                                }
                                                        py-1 px-4 hover:text-white hover:bg-main-400 rounded-full`}
                                                            >
                                                                <Element
                                                                    to={navbar.path}
                                                                    state={
                                                                        navbar?.state && {
                                                                            ...navbar.state,
                                                                        }
                                                                    }
                                                                    // className={` ${isActive && 'text-white bg-main-500' }`}
                                                                >
                                                                    {navbar.content}
                                                                </Element>
                                                            </li>
                                                        );
                                                    }
                                                    return (
                                                        <li className="py-1 px-4 hover:text-white hover:bg-main-400 rounded-full">
                                                            <a
                                                                href={navbar.path}
                                                                // target='_blank'
                                                                // className="hover:text-sky-500 "
                                                            >
                                                                {navbar.content}
                                                            </a>
                                                        </li>
                                                    );
                                                }
                                            )}
                                            {!loggedIn && (
                                                <>
                                                    {/* <li>
                                                        <Link
                                                            className="hover:text-sky-500 "
                                                            to="#"
                                                            onClick={showLoginForm}
                                                        >
                                                            Đăng nhập
                                                        </Link>
                                                    </li> */}
                                                    <li>
                                                        <button
                                                            id="login-btn"
                                                            className={`border ${
                                                                colorNav
                                                                    ? 'bg-main-400 text-white border-main-400'
                                                                    : 'bg-transparent text-black border-main-500 border-2  hover:text-white hover:border-main-400 hover:bg-main-400'
                                                            }
                                                            flex items-center justify-center w-full  h-8 px-3.5 font-semibold  rounded-full focus:outline-none focus:ring-2  sm:w-auto`}
                                                            // onClick={showRegisterForm}
                                                            onClick={showLoginForm}
                                                        >
                                                            {/* Đăng ký */}
                                                            Đăng nhập
                                                        </button>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </nav>
                                    {loggedIn && (
                                        <>
                                            <div className="flex items-center ml-4 border-l border-slate-200 ">
                                                <label
                                                    className="sr-only"
                                                    id="headlessui-listbox-label-2"
                                                >
                                                    Chế độ xem
                                                </label>
                                                {/* <button
                                                    type="button"
                                                    id="headlessui-listbox-button-3"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    aria-labelledby="headlessui-listbox-label-2 headlessui-listbox-button-undefined"
                                                >
                                                    <span className="">
                                                        <FaBell className=" text-slate-400" />
                                                    </span>
                                                    <span className="hidden ">
                                                        <FaMoon className=" text-slate-400" />
                                                    </span>
                                                </button> */}
                                                {/* <Link
                                            to="/tai-khoan"
                                            className="block ml-6 text-slate-400 hover:text-slate-500 "
                                        > */}

                                                <div className="relative ml-3 cursor-pointer">
                                                    <div
                                                        className="flex"
                                                        // onClick={funTogglePopper}
                                                        onMouseEnter={() => setIsOpenPopper(true)}
                                                        onMouseLeave={() => setIsOpenPopper(false)}
                                                    >
                                                        <span className="sr-only">Avatar User</span>
                                                        <Avatar
                                                            username={auth.user.user_name}
                                                            avatarUrl={auth.user.avatarUrl}
                                                            userRole={userRole}
                                                        />
                                                        <FaCaretDown className="text-black text-xs ml-0.5 mt-2" />
                                                    </div>
                                                    {isOpenPopper && (
                                                        <Popover
                                                            username={auth.user.user_name}
                                                            fullname={auth.user.fullname}
                                                            userAvatar={auth.user.avatarUrl}
                                                            setIsOpenPopper={setIsOpenPopper}
                                                            userRole={userRole}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default NavbarDefault;
