/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { useEffect, useState, useRef } from 'react';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
import PublishSlideService from './PublishSlide.service';
import BaseModal from '../../../../components/BaseModal';
// import Templates from '../events/components/Templates';
import { useParams, useNavigate, Link } from 'react-router-dom';
import themeCommon from '../../PublishMainMenu.config';
// import { setContentMeta } from '../../utils/MetaTag';
import { Helmet } from 'react-helmet-async';
import { Cropper } from 'react-mobile-cropper';
// import { CropperCanvas } from 'react-advanced-cropper';
import 'react-mobile-cropper/dist/style.css';
// import Banks from '../../../../resource/wedding/_input/components/Banks';
import ToastNotify from '../../../../utils/Toast';
import EventsService from '../../../events/Events.service';

import PAGE from '../../../../config/path.route';
import ModalLeavingPage from '../../../events/components/ModalLeavingPage';
import SlideSkeleton from './SlideSkeleton';
import { validateSizeOfFile } from '../../../../utils/common';
import LoadingButtonIcon from '../../../../components/LoadingButton/LoadingButtonIcon';
import ShortcutMenu from '../../components/ShortcutMenu';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import {
    FaBook,
    FaChartPie,
    FaExternalLinkAlt,
    FaPen,
    FaPlus,
    FaSave,
    FaTimes,
} from 'react-icons/fa';
import IMAGES from '../../../../assets/images/_index';
import PreviewAlbum from '../publish.album/components/PreviewAlbum';
import QuestionTitle from '../publish.question/QuestionTitle';
import { checkTypeTemplate, limitString } from '../publish.greeting/PublishGreeting.page';
import { useSelector } from 'react-redux';
// import { FaBirthdayCake, FaInfoCircle, FaUserCircle, FaMinusCircle, FaPlusCircle} from 'react-icons/fa';

const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // infoParty
    if (dataCopy?.infoParty) {
        arrCopy = dataCopy.infoParty;
        keyName = 'infoParty';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(JSON.parse(arrCopy));
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

const CropperModal = ({
    index,
    templateAlias,
    setOpenModal,
    setNormalObj,
    normalObj,
    setIsEdit,
    tempImg,
}) => {
    const cropperRef = useRef(null);
    const [image, setImage] = useState(normalObj?.imageSlides[index]?.image ?? tempImg);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);

    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImage(image);
        });
        reader.readAsDataURL(val);
    };

    const submitImageForm = async () => {
        setIsLoadingBtn(true);
        // const img = cropperRef.current.getCanvas()?.toDataURL('image/jpeg', 1);
        const img = cropperRef.current.getCanvas()?.toDataURL();
        // const size_in_bytes = window.atob(img.split(',')[1]).length;
        const file = await PublishSlideService.createFile(img);

        if (
            validateSizeOfFile(
                file,
                20,
                'Dung lượng ảnh sau khi chỉnh sửa không được vượt quá 20Mb'
            )
        ) {
            const change = Math.max(
                ...(normalObj?.imageSlides ?? []).map((x) =>
                    isNaN(Number(x.id)) ? -1 : Number(x.id)
                )
            );
            const max = normalObj?.imageSlides?.length > 0 ? change : 1;
            setNormalObj({
                ...normalObj,
                ['imageSlides']:
                    index !== null
                        ? (normalObj?.imageSlides ?? []).map((x, idx) =>
                              idx === index ? { image: img, id: x.id } : x
                          )
                        : normalObj?.imageSlides.concat([{ image: img, id: (max + 1).toString() }]),
            });
            setIsEdit(true);
            setOpenModal(false);
        }
        setIsLoadingBtn(false);
    };
    return (
        <div className="mx-1.5 appearance-none justify-center rounded-md bg-white px-2 pt-2 transition  hover:border-gray-400 focus:outline-none">
            <Cropper
                stencilProps={{
                    // eslint-disable-next-line no-magic-numbers
                    aspectRatio: 16 / 9,
                }}
                ref={cropperRef}
                style={{ height: '500px', width: '500px' }}
                className="cropper h-[500px]"
                src={image}
            />
            <div className="m-2 flex justify-center gap-2">
                <label
                    className={`flex cursor-pointer items-center gap-[6px] rounded-lg bg-blue-100 px-[16px] py-[8px] text-[16px] font-semibold text-blue-700 hover:bg-blue-200 ${
                        isLoadingBtn
                            ? 'cursor-auto bg-slate-100 text-slate-700 hover:bg-blue-100'
                            : ''
                    }`}
                    // onClick={() => btnRemoveImagePreview('avatarGroom')}
                >
                    <input
                        className="hidden"
                        disabled={isLoadingBtn}
                        onChange={(event) => changeFile(event)}
                        type="file"
                        accept="image/*"
                    />
                    <FaPen /> Thay đổi
                </label>
                <button
                    className={`${
                        isLoadingBtn ? 'bg-blue-500' : 'bg-blue-600 hover:bg-blue-700 '
                    } flex items-center gap-[6px] rounded-lg  px-[16px] py-[8px] text-[16px] font-semibold text-white disabled:bg-slate-700 `}
                    onClick={() => submitImageForm('imageSlides')}
                    disabled={isLoadingBtn}
                >
                    {isLoadingBtn && <LoadingButtonIcon />}
                    {!isLoadingBtn && <FaSave />}
                    Lưu
                </button>
            </div>
        </div>
    );
};

export default function PublishSlide() {
    const navigate = useNavigate();
    const { eventId, url_guest: urlGuest } = useParams();
    const [formData, setFormData] = useState({});
    const [templateAlias, setTemplateAlias] = useState('');
    const [normalObj, setNormalObj] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [load, setLoad] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [imgIdx, setimgIdx] = useState(null);
    const [tempImg, setTempImg] = useState(null);
    const [listDel, setListDel] = useState([]);
    const [isOpenLeavinModal, setOpenLeavinModal] = useState(false);
    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
    });
    const toastLoadingId = useRef(null);
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const { loggedIn } = useSelector((state) => state.auth);

    const confirmForm = (event) => {
        event.preventDefault();
        // setOpenLeavinModal(true);
    };

    const handleAddFromAlbum = (imageUrl) => {
        setTempImg(imageUrl);
        setimgIdx(null);
        setOpenModal2(false);
        setOpenModal(true);
    };

    const submitForm = async () => {
        try {
            toastLoadingId.current = ToastNotify.Loading('Đang lưu ảnh...');
            const imgList = JSON.parse(JSON.stringify(normalObj?.imageSlides));
            const listDels = [...new Set(listDel)];
            if (listDels.length > 0) {
                for (let ind = 0; ind < listDels.length; ind++) {
                    await PublishSlideService.saveContent(formData._id, {
                        imageSlides: listDels[ind],
                    });
                }
            }
            // eslint-disable-next-line no-magic-numbers
            if (imgList.length > 0) {
                const max =
                    Math.max(...imgList.map((x) => (isNaN(Number(x.id)) ? -1 : Number(x.id)))) ?? 0;
                let step = 1;
                for (let ind = 0; ind < imgList.length; ind++) {
                    const file = await PublishSlideService.createFile(imgList[ind].image, 'slide');
                    if (file !== '') {
                        await PublishSlideService.saveFiles(
                            formData._id,
                            'imageSlides',
                            file,
                            imgList[ind].id ?? (max + step).toString(),
                            'image'
                        );
                        step++;
                    }
                }
            }
            setIsEdit(false);
            setLoadData(!loadData);
            ToastNotify.Update(toastLoadingId.current, 'Lưu ảnh thành công!');
            toastLoadingId.current = null;
        } catch (error) {
            setIsEdit(false);
            setLoadData(!loadData);
            ToastNotify.Update(
                toastLoadingId.current,
                error?.message || 'Đã có lỗi xảy ra. Vui lòng thử lại',
                'error'
            );
            toastLoadingId.current = null;
        }
    };
    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        setTempImg(null);
        reader.addEventListener('load', () => {
            const image = reader.result;
            setTempImg(image);
            setimgIdx(null);
            setOpenModal(true);
        });
        reader.readAsDataURL(val);
    };

    const btnRemoveImage = (id) => {
        setListDel(listDel.concat(id));
        // console.log(listDel);
        setNormalObj({
            ...normalObj,
            imageSlides: normalObj.imageSlides.filter((x) => id !== x.id),
        });
        setIsEdit(true);
        // console.log(normalObj.imageSlides.filter(x=>!listDel.concat(id).includes(x.id)));
    };

    // const onChangeInput = (event) => {
    //     setNormalObj({ ...normalObj, [event.target.name]: event.target.value });
    // };

    useEffect(() => {
        if (eventId) {
            setLoad(true);
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    //     const { templateAlias, formData, guest, title, thumbnail, data, eventConfig
                    // } =
                    //         res;
                    const title = data.form_data.titleMeta || data.name;
                    const thumbnail = data.thumbnail_url;
                    const templateAlias = data.template.alias;
                    const formData = data.form_data;
                    const url_slug = data.url_slug;
                    // const jsonData = parseJsonData({ ...data, ...formData });
                    // setContentMeta({ title, image: thumbnail })
                    setValueMeta({
                        title,
                        thumbnail,
                        url_slug,
                    });
                    setIsExpiredEvent(data?.isExpired);
                    setTemplateAlias(templateAlias);
                    // setGuest(guest);
                    setNormalObj({
                        dateCeremony: formData.dateCeremony,
                        timeCeremony: formData.timeCeremony,
                        addressCeremony: formData.addressCeremony,
                        placeCeremony: formData.placeCeremony,
                        nameSupporter: formData.nameSupporter,
                        phoneSupporter: formData.phoneSupporter,
                        imageSlides:
                            formData.imageSlides.length > 0
                                ? formData.imageSlides.map((x) => ({
                                      image: process.env.REACT_APP_SERVER_URL + x.image,
                                      id: x.id,
                                  }))
                                : [],
                    });
                    setListDel([]);

                    setFormData(parseJsonData({ ...data, ...formData }));
                    // setEventConfig(eventConfig);
                })
                .catch((error) => {
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoad(false);
                    }, 500);
                });
        }
    }, [loadData, eventId, urlGuest, navigate, templateAlias]);

    useEffect(() => {
        if (Number(normalObj?.imageSlides?.length) !== Number(formData?.imageSlides?.length)) {
            setIsEdit(true);
        } else if (
            Number(normalObj?.imageSlides?.length) === Number(formData?.imageSlides?.length)
        ) {
            for (let index = 0; index < formData?.imageSlides?.length; index++) {
                if (
                    normalObj?.imageSlides[index]?.id !== formData?.imageSlides[index]?.id ||
                    normalObj?.imageSlides[index]?.image !==
                        process.env.REACT_APP_SERVER_URL + formData?.imageSlides[index]?.image[0]
                ) {
                    setIsEdit(true);
                    return;
                }
            }
            setIsEdit(false);
        }
    }, [formData, normalObj]);

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    return (
        <div>
            <Helmet>
                <title>{'Ảnh trình chiếu' + ' | ' + valueMeta.title}</title>
                <meta property="og:image" content={valueMeta.thumbnail} />
            </Helmet>
            <div className={'h-[100dvh] w-[100vw] bg-gray-50 text-black'}>
                <div className="sticky top-0 z-[40] bg-white md:bg-gray-50 md:px-4 lg:px-6">
                    <div className="relative flex w-full flex-wrap items-center justify-between gap-x-3 p-4 md:px-0 lg:flex-nowrap lg:gap-4 lg:py-5">
                        <div className="flex md:flex-row flex-col md:gap-2">
                            <button
                                className="flex items-center"
                                type="button"
                                onClick={() => {
                                    navigate(`/${eventId}/mainmenu`);
                                }}
                                title="Trở về"
                            >
                                <i className=" fas fa-chevron-left"></i>
                                <p className="order-1 ml-3 font-bold text-black">
                                    {limitString(formData?.name)}
                                </p>
                            </button>
                            {isExpiredEvent && (
                                <div className="h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit">
                                    <span className="text-[9px] text-white uppercase">
                                        {checkTypeTemplate(templateAlias)}
                                    </span>
                                </div>
                            )}
                            {!loggedIn && (
                                <div className="h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit">
                                    <span className="text-[9px] text-white uppercase">
                                        Bạn chưa đăng nhập
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-center gap-2 overflow-hidden ">
                            {loggedIn && (
                                <Link
                                    // target="_blank"
                                    className="inline-flex select-none gap-2 rounded-md bg-white border border-blue-500 px-3 py-1 text-center font-medium text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-100 xl:px-4 text-sm"
                                    to={`..${PAGE.profile}`}
                                    state={{ isResourcePackage: true }}
                                >
                                    Quản lý gói dịch vụ
                                    <FaChartPie className="my-auto" />
                                </Link>
                            )}
                            <button className="inline-flex w-max select-none rounded-md bg-blue-500 px-3 py-1 text-center font-medium text-white transition-all duration-150 ease-linear hover:bg-blue-600 xl:px-4 text-sm">
                                <Link
                                    target="_blank"
                                    className="flex gap-2"
                                    to={'../' + valueMeta?.url_slug}
                                >
                                    Xem thiệp <FaExternalLinkAlt className="my-auto" />
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="w-full border-b border-gray-300"></div>
                </div>
                <form className="p-4" onSubmit={confirmForm}>
                    <div className="flex w-full justify-evenly">
                        <div className="relative hidden w-[250px] lg:flex">
                            <TimelineShortcutMenu screen={'slide'} />
                        </div>
                        <div className=" relative min-h-[500px] w-full  rounded-lg pb-3 md:col-span-2 lg:w-[48rem] 2xl:w-[60rem]">
                            <div className="mx-auto mb-5 max-h-[90dvh] w-full scroll-mt-[100px] rounded-lg bg-white pb-3 shadow md:col-span-2 ">
                                <div className="flex flex-col border-b border-gray-200 p-2  px-3">
                                    <p className="sticky mb-0 text-lg font-bold text-gray-900">
                                        Ảnh trình chiếu
                                    </p>
                                    <QuestionTitle keyQues={'slider'} />
                                </div>
                                {load && <SlideSkeleton />}
                                {!load && (
                                    <div className="m-2 max-h-[80dvh] flex-auto overflow-y-auto px-6 py-2">
                                        {!isExpiredEvent && (
                                            <div className=" w-full flex justify-end gap-4">
                                                <div>
                                                    <label className="hidden cursor-pointer gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:flex ">
                                                        <FaPlus className="mr-0.5 mt-0.5" />
                                                        Thêm ảnh
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            onChange={(event) =>
                                                                changeFile(event, 'album')
                                                            }
                                                            multiple="multiple"
                                                            accept="image/*"
                                                            disabled={isExpiredEvent}
                                                        />
                                                    </label>
                                                    <label className="block cursor-pointer gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:hidden">
                                                        <FaPlus />
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            disabled={isExpiredEvent}
                                                            onChange={(event) =>
                                                                changeFile(event, 'imageSlides')
                                                            }
                                                            accept="image/*"
                                                        />
                                                    </label>
                                                </div>
                                                <div>
                                                    <button
                                                        className="hidden gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:flex "
                                                        onClick={() => {
                                                            setOpenModal2(true);
                                                        }}
                                                    >
                                                        <FaBook className="mr-0.5 mt-0.5" />
                                                        Chọn từ Album
                                                    </button>
                                                    <button
                                                        className="block cursor-pointer gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:hidden"
                                                        onClick={() => {
                                                            setOpenModal2(true);
                                                        }}
                                                    >
                                                        <FaBook />
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        <ul className="mt-5 grid grid-cols-1 gap-x-4 gap-y-6 overflow-y-auto md:grid-cols-3">
                                            {/* {((normalObj?.imageSlides &&
                                                normalObj?.imageSlides?.length === 0) ||
                                                normalObj?.imageSlides?.length < 5) && ( */}
                                            {/* <label className="mx-auto min-h-[150px] w-full cursor-pointer  appearance-none justify-center rounded-lg border-2 border-dashed border-gray-300 bg-white transition hover:border-gray-400 focus:outline-none">
                                                <span className="my-auto flex h-full justify-center space-x-2">
                                                    <svg
                                                        className="my-auto fill-gray-400"
                                                        width="32px"
                                                        height="32px"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            x="0"
                                                            fill="none"
                                                            width="24"
                                                            height="24"
                                                        />
                                                        <g>
                                                            <path d="M23 4v2h-3v3h-2V6h-3V4h3V1h2v3h3zm-8.5 7c.828 0 1.5-.672 1.5-1.5S15.328 8 14.5 8 13 8.672 13 9.5s.672 1.5 1.5 1.5zm3.5 3.234l-.513-.57c-.794-.885-2.18-.885-2.976 0l-.655.73L9 9l-3 3.333V6h7V4H6c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-7h-2v3.234z" />
                                                        </g>
                                                    </svg>
                                                    <span className="my-auto text-gray-400">
                                                        Thêm Ảnh (Giới hạn 10Mb)
                                                    </span>
                                                    <input
                                                        type="file"
                                                        className="hidden"
                                                        disabled={isExpiredEvent}
                                                        onChange={(event) =>
                                                            changeFile(event, 'imageSlides')
                                                        }
                                                        accept="image/*"
                                                    />
                                                </span>
                                            </label> */}
                                            {/* )} */}
                                            {normalObj?.imageSlides?.length > 0 &&
                                                !isExpiredEvent && (
                                                    <label
                                                        multiple
                                                        className="block w-full cursor-pointer justify-center rounded-lg border-2 border-dashed border-gray-300 bg-white transition hover:border-gray-400 focus:outline-none"
                                                    >
                                                        <span className="my-auto flex h-full flex-row justify-center text-center md:flex-col md:justify-start 2xl:flex-row 2xl:justify-center">
                                                            <svg
                                                                className="my-auto fill-gray-400 md:mx-auto md:mb-0 2xl:mx-0 2xl:my-auto"
                                                                width="32px"
                                                                height="32px"
                                                                viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <rect
                                                                    x="0"
                                                                    fill="none"
                                                                    width="24"
                                                                    height="24"
                                                                />
                                                                <g>
                                                                    <path d="M23 4v2h-3v3h-2V6h-3V4h3V1h2v3h3zm-8.5 7c.828 0 1.5-.672 1.5-1.5S15.328 8 14.5 8 13 8.672 13 9.5s.672 1.5 1.5 1.5zm3.5 3.234l-.513-.57c-.794-.885-2.18-.885-2.976 0l-.655.73L9 9l-3 3.333V6h7V4H6c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-7h-2v3.234z" />
                                                                </g>
                                                            </svg>
                                                            <span className="my-auto text-gray-400 md:mt-0 2xl:my-auto">
                                                                Thêm Ảnh (Giới hạn 10Mb)
                                                            </span>
                                                            <input
                                                                type="file"
                                                                className="hidden"
                                                                disabled={isExpiredEvent}
                                                                onChange={(event) =>
                                                                    changeFile(event, 'imageSlides')
                                                                }
                                                                accept="image/*"
                                                            />
                                                        </span>
                                                    </label>
                                                )}
                                            {normalObj?.imageSlides?.length > 0 &&
                                                normalObj?.imageSlides
                                                    ?.sort(
                                                        (start, end) =>
                                                            Number(start.id) - Number(end.id)
                                                    )
                                                    ?.map((item, index) => (
                                                        <li key={index} className="relative h-max">
                                                            <div
                                                                className="group relative block w-full overflow-hidden rounded-lg bg-gray-100"
                                                                style={{
                                                                    '--tw-aspect-h': 9,
                                                                    '--tw-aspect-w': 16,
                                                                    position: 'relative',
                                                                    paddingBottom:
                                                                        'calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)',
                                                                }}
                                                            >
                                                                <img
                                                                    src={item.image}
                                                                    className="absolute inset-0 mx-auto size-full rounded-lg object-cover"
                                                                    alt="Cô dâu"
                                                                />
                                                                <button
                                                                    type="button"
                                                                    key={index}
                                                                    disabled={isExpiredEvent}
                                                                    className="absolute -top-0 flex size-full flex-col items-center justify-center rounded-lg bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100"
                                                                    onClick={() => {
                                                                        setimgIdx(index);
                                                                        setOpenModal(true);
                                                                    }}
                                                                >
                                                                    <span className="fas fa-edit cursor-pointer text-3xl text-slate-100"></span>
                                                                </button>
                                                                {!isExpiredEvent && (
                                                                    <div
                                                                        onClick={() =>
                                                                            btnRemoveImage(item.id)
                                                                        }
                                                                        className="absolute right-3 top-1 w-[33px] cursor-pointer rounded-full border bg-slate-100 p-2 text-black shadow-lg hover:bg-gray-400"
                                                                    >
                                                                        <svg
                                                                            width="15px"
                                                                            height="15px"
                                                                            viewBox="0 0 24 24"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                fill="none"
                                                                                stroke="#000"
                                                                                strokeWidth="2"
                                                                                d="M3,3 L21,21 M3,21 L21,3"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </li>
                                                    ))}
                                        </ul>
                                        <BaseModal
                                            isOpen={openModal}
                                            onClose={() => {
                                                setOpenModal(false);
                                            }}
                                            modalTitle="Chỉnh sửa ảnh trình chiếu"
                                            width="max-w-[700px]"
                                        >
                                            <CropperModal
                                                index={imgIdx}
                                                normalObj={normalObj}
                                                setTempImg={setTempImg}
                                                setIsEdit={setIsEdit}
                                                setNormalObj={setNormalObj}
                                                setOpenModal={setOpenModal}
                                                templateAlias={templateAlias}
                                                key={imgIdx}
                                                tempImg={tempImg}
                                            />
                                        </BaseModal>
                                    </div>
                                )}
                                {!load && !(normalObj?.imageSlides?.length > 0) && (
                                    <div className="flex w-full flex-col items-center justify-center">
                                        <img
                                            src={IMAGES.SLIDER}
                                            alt="not found"
                                            className="mx-auto w-1/3 opacity-70"
                                        />
                                        <h4 className="mt-3 text-center text-xl text-blue-900 ">
                                            Thiệp mời của bạn sẽ trở nên thật đặc biệt với những
                                            hình ảnh trình chiếu.
                                        </h4>
                                        <p className="mt-2 text-center text-sm text-black">
                                            Hãy lựa chọn một số hình ảnh đẹp, ấn tượng nhất để thiệp
                                            mời của bạn trở nên thật ấn tượng trong mắt mọi người!
                                            {!isExpiredEvent && (
                                                <p>
                                                    <label className="inline-block cursor-pointer font-bold text-blue-500 underline">
                                                        Thêm ảnh ngay
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            onChange={(event) =>
                                                                changeFile(event, 'album')
                                                            }
                                                            multiple="multiple"
                                                            accept="image/*"
                                                            disabled={isExpiredEvent}
                                                        />
                                                    </label>
                                                    {' hoặc '}
                                                    <div
                                                        className="inline-block cursor-pointer font-bold text-blue-500 underline"
                                                        onClick={() => {
                                                            setOpenModal2(true);
                                                        }}
                                                    >
                                                        Chọn từ Album
                                                    </div>
                                                </p>
                                            )}
                                        </p>
                                    </div>
                                )}
                                {!isExpiredEvent && (
                                    <div
                                        className={
                                            (isEdit
                                                ? 'bottom-0 opacity-100'
                                                : '-bottom-80 opacity-0') +
                                            ' fixed inset-x-0 z-[40] bg-gray-50 transition-all duration-500 md:px-6 lg:z-[40]'
                                        }
                                    >
                                        <div className="flex items-center justify-between gap-3 border-t border-gray-300 px-6 py-4 md:px-0">
                                            <p>Chưa lưu !</p>
                                            <div className="flex gap-3">
                                                <button
                                                    type="button"
                                                    className={
                                                        'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-100 px-6 py-2 text-center font-semibold text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-200 '
                                                    }
                                                    onClick={() => {
                                                        setIsEdit(false);
                                                        setLoadData(!loadData);
                                                    }}
                                                >
                                                    <FaTimes />
                                                    Hủy bỏ
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={() => {
                                                        setOpenLeavinModal(true);
                                                    }}
                                                    className={
                                                        'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-500 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 '
                                                    }
                                                >
                                                    <FaSave />
                                                    Hoàn tất
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <PreviewAlbum
                                    open={openModal2}
                                    handleClose={() => setOpenModal2(false)}
                                    handlePass={handleAddFromAlbum}
                                />
                                <ModalLeavingPage
                                    saveChanged={submitForm}
                                    isOpen={isOpenLeavinModal}
                                    confirmNavigation={() => {
                                        setOpenLeavinModal(false);
                                    }}
                                    key={formData._id}
                                    funToggle={() => {
                                        setOpenLeavinModal(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="relative hidden w-[250px] lg:flex">
                            <Products />
                        </div>
                    </div>
                </form>
                {/* <ShortcutMenu screen={'slide'} /> */}
            </div>
        </div>
    );
}
