/* eslint-disable  */

import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
import PublishSummaryService from './PublishSummary.service';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// import Templates from '../events/components/Templates';
import { useParams, useNavigate } from 'react-router-dom';
import { Cropper } from 'react-mobile-cropper';
// import BaseModal from '../../../../components/BaseModal';
import PAGE from '../../../../config/path.route';
import ModalLeavingPage from '../../../events/components/ModalLeavingPage';
// import { setContentMeta } from '../../utils/MetaTag';
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async';
import 'react-mobile-cropper/dist/style.css';
import ToastNotify from '../../../../utils/Toast';
import EventsService from '../../../events/Events.service';
import SummarySkeleton from './SummarySkeleton';

import { FaChevronCircleDown, FaChevronCircleUp, FaTimes, FaSave } from 'react-icons/fa';

import envConstants from '../../../../config/key';

import Regex from '../../../../utils/Regex';

import QuestionTitle from '../publish.question/QuestionTitle';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import moment from 'moment';
import Header from '../publish.sub/components/Header';
import { useSelector } from 'react-redux';

registerLocale('vi', vi);
const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // infoParty
    if (dataCopy?.infoParty) {
        arrCopy = dataCopy.infoParty;
        keyName = 'infoParty';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(JSON.parse(arrCopy));
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

const regexStr = /^[^\\(\\)~`#$%^&*=|\?\/<>\\*]+$/g;

const EnventSummary = ({
    normalObj,
    templateAlias,
    setLoad,
    load,
    isExpired,
    setIsEditShortcut,
}) => {
    const schema = yup.object().shape({
        titleEvent: yup
            .string()
            .required('Vui lòng nhập tiêu để!')
            .max(200, 'Tiêu đề không được quá 200 ký tự!'),
        nameEvent: yup
            .string()
            .required('Vui lòng nhập tên sự kiện!')
            .max(200, 'Tên sự kiện không được quá 200 ký tự!'),
        invitation: yup
            .string()
            .required('Vui lòng nhập lời mời!')
            .max(200, 'Lời mời không được quá 200 ký tự!'),
        nameTemplate: yup
            .string()
            .trim()
            .required('Vui lòng nhập tên thiệp của bạn!')
            .max(200, 'Tên thiệp không vượt quá 200 ký tự!'),
        groupEmail: yup
            .string()
            .matches(Regex.email, {
                excludeEmptyString: true,
                message:
                    'Email: sử dụng các chữ cái (a-z), số (0-9), dấu chấm (.), và dấu a còng (@).',
            })
            .max(100, 'Vui lòng nhập email không vượt quá 100 kí tự')
            .nullable(true),

        linkGgMap: yup.string().when({
            is: (exists) => !!exists,
            then: (rule) => rule.matches(Regex.url, 'Đường dẫn không hợp lệ!'),
        }),
    });
    const [content, setContent] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [isOpenLeavinModal, setOpenLeavinModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [tempImg, setTempImg] = useState(null);
    const [isDeleteLogo, setDeleteLogo] = useState(false);
    const [checkDeleteLogo, setCheckDeleteLogo] = useState(false);
    const [selectedDate, setSelectedDate] = useState(
        content?.dateHold ? new Date(content.dateHold) : null
    );

    const handleDelete = () => {
        setDeleteLogo(true);
        setCheckDeleteLogo(true);
        setIsEdit(true);
        setContent({
            ...content,
            logo: [],
        });
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        triggerValidation,
        // watch
        setValue,
    } = useForm({ resolver: yupResolver(schema), mode: 'onSubmit', reValidateMode: 'onChange' });

    const onChangeInput = (event) => {
        // if (normalObj[event.target.name] !== event.target.value) {
        setIsEdit(true);
        setIsEditShortcut(true);
        // } else {
        //     setIsEdit(false);
        // }
        setContent({ ...content, [event.target.name]: event.target.value });
    };
    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        setTempImg(null);
        reader.addEventListener('load', () => {
            const image = reader.result;
            setTempImg(image);
            setOpenModal(true);
        });
        reader.readAsDataURL(val);
    };
    useEffect(() => {
        setContent({
            _id: normalObj._id,
            titleEvent: normalObj?.titleEvent?.length > 0 ? normalObj.titleEvent : 'Thư mời',
            nameEvent: normalObj.nameEvent,
            invitation:
                normalObj?.invitation?.length > 0 ? normalObj.invitation : 'Kính mời tham dự',
            dateHold: normalObj?.dateHold,
            groupName: normalObj?.groupName,
            timeHold: normalObj?.timeHold,
            groupNameBrief: normalObj?.groupNameBrief,
            groupEmail: normalObj?.groupEmail,
            addressHold: normalObj?.addressHold,
            placeHold: normalObj?.placeHold,
            nameSupporter: normalObj?.nameSupporter,
            phoneSupporter: normalObj?.phoneSupporter,
            nameTemplate: normalObj?.nameTemplate,
            logo: normalObj.logo ? process.env.REACT_APP_SERVER_URL + normalObj.logo : null,
            linkGgMap: normalObj?.linkGgMap ? normalObj?.linkGgMap : '',
        });
        if (normalObj?.titleEvent?.length == 0 || normalObj?.invitation?.length == 0) {
            setIsEdit(true);
        }
        setValue(
            'titleEvent',
            normalObj?.titleEvent?.length > 0 ? normalObj.titleEvent : 'Thư mời'
        );
        setValue('nameEvent', normalObj.nameEvent);
        setValue(
            'invitation',
            normalObj?.invitation?.length > 0 ? normalObj.invitation : 'Kính mời tham dự'
        );
        setValue('nameTemplate', normalObj.nameTemplate);
        setCheckDeleteLogo(false);
    }, [normalObj, setValue]);
    const submitHandle = async () => {
        setOpenLeavinModal(true);
    };
    const submitForm = async () => {
        delete content?.logo;
        // PublishSummaryService.saveContent(content._id, content)
        //     .then(({ data }) => {
        //         ToastNotify.Success('Lưu thành công');
        //         const event = data.data;
        //         if (event?.is_public) {
        //             screenShot(event._id, event?.url_slug);
        //         }
        //     })
        //     .catch(() => ToastNotify.Error('Đã có lỗi xảy ra.'))
        //     .finally(() => {
        //         setLoad(!load);
        //         setIsEdit(false);
        //     });
        const promises = [
            PublishSummaryService.saveContent(content._id, {
                ...content,
                ...(checkDeleteLogo ? { logo: [] } : {}),
            }),
            EventsService.updateEventTemplate(content._id, { name: content.nameTemplate }),
        ];
        Promise.all(promises)
            .then((responses) => {
                const arrEvents = responses.map(({ data }) => data.data);
                const [event] = arrEvents;
                ToastNotify.Success('Lưu thành công');
                // if (event?.is_public) {
                //     screenShot(event._id, event?.url_slug);
                // }
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra'))
            .finally(() => {
                setLoad(!load);
                setIsEdit(false);
                setIsEditShortcut(false);
            });
    };

    const handleShowOtherInfo = () => {
        setShowOtherInfo(!showOtherInfo);
    };

    const handleDateChange = (date) => {
        onChangeInput({
            target: { name: 'dateHold', value: date ? moment(date).format('YYYY-MM-DD') : '' },
        });
    };

    return (
        <form
            className="flex flex-col"
            onSubmit={(event) => {
                event.preventDefault();
                handleSubmit(submitHandle)(event).catch((err) => {
                    console.log('handleSubmit err = ', err);
                });
            }}
            noValidate={true}
        >
            <div className="mb-5 scroll-mt-[100px] rounded-lg bg-white shadow">
                <div className="w-full py-2 px-4 border-b-[1px] text-lg border-gray-300">
                    <p className="mb-0 font-bold text-gray-900 text-lg">Thông tin khác</p>
                </div>

                <div className="mb-4 flex flex-col gap-2 h-auto">
                    <div className="w-full p-4">
                        <div className="block w-full">
                            <span className="mb-5 text-sm font-normal">Tên tổ chức</span>
                            <div className="flex w-full">
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="groupName"
                                        value={content?.groupName}
                                        onInput={onChangeInput}
                                        placeholder={`VD: "Công ty ABC"`}
                                        disabled={isExpired}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full px-4 py-1">
                        <div className="block w-full">
                            <span className="mb-5 text-sm font-normal">Tên tổ chức viết tắt</span>
                            <div className="flex w-full">
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="groupNameBrief"
                                        value={content?.groupNameBrief}
                                        onInput={onChangeInput}
                                        placeholder={`VD: "ABC"`}
                                        disabled={isExpired}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full px-4 py-1">
                        <div className="block w-full">
                            <span className="mb-5 text-sm font-normal">Email chủ sự kiện</span>
                            <div className="flex w-full">
                                <div className=" w-full flex flex-col gap-1">
                                    <input
                                        type="text"
                                        name="groupEmail"
                                        value={content?.groupEmail}
                                        onInput={onChangeInput}
                                        placeholder={`VD: "nguyenvan***@gmail.com"`}
                                        disabled={isExpired}
                                        {...register('groupEmail')}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                    />
                                    <span className="text-xs font-medium text-red-500">
                                        {errors.groupEmail && errors.groupEmail?.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="px-4">
                        <QuestionTitle keyQues={'supporter_ev'} />
                    </div>
                    <div className="block w-full px-4 py-1">
                        <span className="mb-5 text-sm font-normal">Họ và tên người hỗ trợ</span>
                        <div className="flex w-full">
                            <div className="w-full">
                                <input
                                    type="text"
                                    name="nameSupporter"
                                    value={content?.nameSupporter}
                                    onInput={onChangeInput}
                                    placeholder={`VD: "Nguyễn Văn A"`}
                                    disabled={isExpired}
                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="block w-full px-4 py-1 pb-4">
                        <span className="mb-5 text-sm font-normal">Số điện thoại người hỗ trợ</span>
                        <div className="flex w-full">
                            <div
                                className="w-full"
                                // onClick={() => {
                                //     setHighlightContext('addressBride');
                                // }}
                            >
                                <input
                                    type="text"
                                    name="phoneSupporter"
                                    value={content?.phoneSupporter}
                                    onInput={onChangeInput}
                                    placeholder={`VD: "0124567809"`}
                                    disabled={isExpired}
                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={(isEdit ? '' : 'hidden') + ' py-6 md:px-12 lg:z-[40]'}></div>
            {!isExpired && (
                <div
                    className={
                        (isEdit ? 'bottom-0 opacity-100' : '-bottom-80 opacity-0') +
                        ' fixed inset-x-0 z-[40] bg-gray-50 transition-all duration-500 md:px-6 lg:z-[40]'
                    }
                >
                    <div className="flex items-center justify-between gap-3 border-t border-gray-300 px-6 py-4 md:px-0">
                        <p>Chưa lưu !</p>
                        <div className="flex gap-3">
                            <button
                                type="button"
                                className={
                                    'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-100 px-6 py-2 text-center font-semibold text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-200 '
                                }
                                onClick={() => {
                                    setIsEdit(false);
                                    setIsEditShortcut(false);
                                    setLoad(!load);
                                }}
                            >
                                <FaTimes />
                                Hủy bỏ
                            </button>
                            <button
                                type="submit"
                                className={
                                    'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-500 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 '
                                }
                            >
                                <FaSave />
                                Hoàn tất
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <ModalLeavingPage
                saveChanged={submitForm}
                isOpen={isOpenLeavinModal}
                confirmNavigation={() => {
                    setOpenLeavinModal(false);
                }}
                funToggle={() => {
                    setOpenLeavinModal(false);
                }}
            />
        </form>
    );
};

export default function PublishSummary() {
    const navigate = useNavigate();
    const { eventId, url_guest: urlGuest } = useParams();

    const [normalObj, setNormalObj] = useState({});
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [templateAlias, setTemplateAlias] = useState('');
    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
    });
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const [isEditShortcut, setIsEditShortcut] = useState(false);
    const [event, setEvent] = useState();
    const { loggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        setLoading(true);
        if (eventId) {
            // const payload = { slug: eventId };
            // if (urlGuest) {
            //     payload.guest = urlGuest;
            // }
            // PublishSummaryService.getPublic(payload)
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    setEvent(data);
                    //     const { templateAlias, formData, guest, title, thumbnail, data, eventConfig
                    // } =
                    //         res;
                    const title = data.form_data.titleMeta || 'ChungVui';
                    const thumbnail = data.thumbnail_url;
                    const templateAlias = data.template.alias;

                    const formData = data.form_data;
                    console.log('formData -----', formData);
                    const jsonData = parseJsonData({ ...data, ...formData });
                    setIsExpiredEvent(data?.isExpired);
                    // setContentMeta({ title, image: thumbnail })
                    setValueMeta({
                        title,
                        thumbnail,
                    });
                    setTemplateAlias(templateAlias);
                    // setGuest(guest);
                    if (templateAlias.includes('wedding')) {
                        setWedding(true);
                        setNormalObj({
                            _id: jsonData?._id,
                            dateCeremony: jsonData?.dateCeremony,
                            timeCeremony: jsonData?.timeCeremony,
                            addressCeremony: jsonData?.addressCeremony,
                            placeCeremony: jsonData?.placeCeremony,
                            linkGgMap: jsonData?.linkGgMap,
                            nameSupporter: jsonData?.nameSupporter,
                            phoneSupporter: jsonData?.phoneSupporter,
                            nameTemplate: jsonData?.name,
                        });
                    } else {
                        setNormalObj({
                            _id: jsonData?._id,
                            titleEvent: jsonData?.titleEvent ?? null,
                            nameEvent: jsonData?.nameEvent,
                            invitation: jsonData?.invitation,
                            logo: jsonData?.logo ? jsonData?.logo[0] : null,
                            dateHold: jsonData?.dateHold,
                            timeHold: jsonData?.timeHold,
                            addressHold: jsonData?.addressHold,
                            groupName: jsonData?.groupName,
                            groupNameBrief: jsonData?.groupNameBrief,
                            groupEmail: jsonData?.groupEmail,
                            placeHold: jsonData?.placeHold,
                            nameSupporter: jsonData?.nameSupporter,
                            phoneSupporter: jsonData?.phoneSupporter,
                            nameTemplate: jsonData?.name,
                            linkGgMap: jsonData?.linkGgMap ?? '',
                        });
                    }

                    // setEventConfig(eventConfig);
                })
                .catch((error) => {
                    console.log(error);
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 400);
                });
        }
    }, [eventId, urlGuest, navigate, templateAlias, load]);

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    const [keyQues, setKeyQues] = useState('');

    return (
        <div>
            <Helmet>
                <title>{valueMeta.title + ' | ' + 'Thông tin chung'}</title>
                <meta property="og:image" content={valueMeta.thumbnail} />
            </Helmet>
            <div className={'relative h-[100dvh] w-[100vw] bg-gray-50 text-black'}>
                <Header event={event} isExpired={isExpiredEvent} />
                <div className="h-full px-[1rem] py-[1.5rem] mt-[60px]">
                    <div className="w-full flex justify-evenly">
                        <div className="lg:flex hidden relative w-[250px]">
                            <TimelineShortcutMenu screen={'subinfo'} isEdit={isEditShortcut} />
                        </div>
                        <div className=" relative min-h-[500px] w-full mb-3 md:col-span-2 lg:w-[48rem] 2xl:w-[60rem]">
                            {loading && <SummarySkeleton />}

                            <EnventSummary
                                normalObj={normalObj}
                                setLoad={setLoad}
                                navigate={navigate}
                                templateAlias={templateAlias}
                                load={load}
                                isExpired={isExpiredEvent}
                                setIsEditShortcut={setIsEditShortcut}
                            />
                        </div>
                        <div className="lg:flex hidden relative w-[250px]">
                            <Products />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const screenShot = (eventId, eventUrlSlug) => {
    // Screen short
    const linkPage = envConstants.CLIENT_URL + eventUrlSlug;
    const linkThumbnail = envConstants.CLIENT_URL + 'thumbnail/' + eventUrlSlug;
    const location = 'events/' + eventId;
    const promises = [
        EventsService.getScreenshot(linkPage, location),
        EventsService.getScreenshot(linkThumbnail, location),
    ];
    Promise.all(promises)
        .then((responses) => {
            const arrPathData = responses.map(({ data }) => data.path);
            const [image, thumbnail] = arrPathData;
            const payload = {
                image: image,
                thumbnail: thumbnail,
            };
            return payload;
        })
        .then((formData) => EventsService.updateEventTemplate(eventId, formData))
        .catch((error) => console.log(error));
};
