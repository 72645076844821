/* eslint-disable */

import { useEffect, useState } from 'react';
import moment from 'moment';
import userService from '../User.service';
import { useSelector } from 'react-redux';
import { formatDatetime } from '../../../utils/Times';
import {
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaAngleLeft,
    FaAngleRight,
    FaEnvelope,
    FaListUl,
} from 'react-icons/fa';
import { FaEnvelopeCircleCheck } from 'react-icons/fa6';
import { MdAssignmentTurnedIn } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import TransactionHistoryDetail from './TransactionHistoryDetail';
import ToastNotify from '../../../utils/Toast';
import ModalPaymentInfo from './ModalPaymentInfo';
import ModalConfirmPaidOrderNew from './ModalConfirmPaidOrderNew';
import ModalConfirmResendEmail from './ModalConfirmResendEmail';
import StatusModal from './StatusModal';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import 'react-datepicker/dist/react-datepicker.css';
import ModalConfirmResendPaidEmail from './ModalConfirmResendPaidEmail';

export const STATUS_TEXT = {
    completed: {
        text: 'Thành công',
        color: 'green-600',
    },
    pending: {
        text: 'Chờ xử lý',
        color: 'gray-400',
    },
    canceled: {
        text: 'Đã hủy',
        color: 'red-600',
    },
    paid: {
        text: 'Đã thanh toán',
        color: 'sky-500',
    },
};

const STATUS = {
    COMPLETED: 'completed',
    PENDING: 'pending',
    CANCELED: 'canceled',
    PAID: 'paid',
};

const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();
const addMonths = (input, months) => {
    const date = new Date(input);
    date.setDate(1);
    date.setMonth(date.getMonth() + months);
    date.setDate(
        Math.min(input.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1))
    );
    return date;
};
// const startOfMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
// const endOfMonth = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
//Tìm kiếm từ 18 tháng trước đến 6 tháng sau
const dateNow = new Date();
const startOfCycle = addMonths(dateNow, -18);
const endOfCycle = addMonths(dateNow, 6);

export default function TransactionHistory({ isReloadTable, trigger }) {
    const userData = useSelector((state) => state.auth.user);

    const [transactionHistories, setTransactionHistories] = useState([]);
    const [dataDetail, setDataDetail] = useState();
    const [orderId, setOrderId] = useState();
    const [searchParams, setSearchParams] = useState({
        fromDate: startOfCycle,
        toDate: endOfCycle,
    });
    const [isReload, setIsReload] = useState(isReloadTable);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [paginate, setPaginate] = useState({
        hasNextPage: false,
        hasNextPage: false,
        hasPrevPage: false,
        nextPage: null,
        prevPage: null,
        totalDocs: 0,
        totalPages: 1,
    });

    const [isOpenPaymentInfo, setIsOpenPaymentInfo] = useState(false);
    const funTogglePaymentInfo = () => {
        setIsOpenPaymentInfo(!isOpenPaymentInfo);
    };
    const [orderInfo, setOrderInfo] = useState();

    const onChangeInputDate = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setSearchParams((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        const payload = {
            user_id: userData._id,
            page,
            limit,
            fromDate: searchParams.fromDate,
            toDate: searchParams.toDate,
        };
        userService
            .postAdminGetOders(payload)
            .then(({ data }) => {
                setTransactionHistories(data.data.docs);
                setPaginate({ ...data.data });
            })
            .catch((err) => console.log(err))
            .finally();
    }, [page, limit, searchParams, isReload, isReloadTable, trigger]);

    // const handleConfirmPaidOrder = () => {
    //     userService.postAdminConfirmPaidOder(userData._id, orderId)
    //         .then(({ data }) => {
    //             funToggleModalConfirmPayment();
    //             funTogglePaymentInfo();
    //             setIsReload(!isReload);
    //             ToastNotify.Success('Xác nhận thanh toán thành công')
    //         })
    //         .catch(err => console.log(err))
    //         .finally()
    // }

    const handleConfirmResendEmail = () => {
        userService
            .postAdminResendOrder(userData._id, dataDetail._id)
            .then(({ data }) => {
                console.log('handleConfirmResendEmail = ', data);
                ToastNotify.Success('Gửi email thành công. Vui lòng kiểm tra email của bạn');
            })
            .catch((err) => ToastNotify.Error('Gửi email bị lỗi'))
            .finally();
    };

    const handleConfirmResendPaidEmail = () => {
        userService
            .postAdminResendPaidOrder(userData._id, dataDetail._id)
            .then(({ data }) => {
                ToastNotify.Success(
                    'Gửi email thành công. Quản trị viên ChungVui sẽ phản hồi bạn trong thời gian sớm nhất.'
                );
            })
            .catch((err) => ToastNotify.Error('Gửi email bị lỗi'))
            .finally();
    };

    const [statusShow, setStatusShow] = useState();
    const [orderDetail, setOrderDetail] = useState();

    const handleDateFromChange = (date) => {
        setSearchParams((prev) => ({
            ...prev,
            ['fromDate']: date ? moment(date).format('YYYY-MM-DD') : '',
        }));
    };

    const handleDateToDate = (date) => {
        setSearchParams((prev) => ({
            ...prev,
            ['toDate']: date ? moment(date).format('YYYY-MM-DD') : '',
        }));
    };

    return (
        <>
            <ModalConfirmResendEmail data={dataDetail} handleConfirm={handleConfirmResendEmail} />
            <ModalConfirmResendPaidEmail
                data={dataDetail}
                handleConfirm={handleConfirmResendPaidEmail}
            />
            <TransactionHistoryDetail data={dataDetail} />
            <StatusModal
                status={statusShow}
                setStatus={setStatusShow}
                detail={orderDetail}
                setDetail={setOrderDetail}
                setIsOpenPaymentInfo={setIsOpenPaymentInfo}
                setOrderInfo={setOrderInfo}
            />
            <ModalPaymentInfo
                isOpen={isOpenPaymentInfo}
                funToggle={funTogglePaymentInfo}
                orderInfo={orderInfo}
                setIsReloadTable={() => setIsReload(!isReload)}
            />
            <div className="mt-2 mb-10">
                <div className="flex flex-wrap sm:flex-row sm:justify-between">
                    <p className="text-black font-bold pt-3">
                        Lịch sử giao dịch
                        <span className="text-gray-500 font-semibold ml-1">
                            (Tổng: {paginate?.totalDocs || 0})
                        </span>
                    </p>
                    <div className="flex flex-row w-full sm:w-fit items-center justify-end gap-x-2">
                        {/* <input
                            type="date"
                            name="fromDate"
                            value={
                                searchParams.fromDate &&
                                moment(searchParams.fromDate).format('YYYY-MM-DD')
                            }
                            onChange={onChangeInputDate}
                            className="rounded border border-gray-300 
                                    bg-white px-3 py-2 text-sm text-blueGray-600 shadow 
                                    transition-all duration-150 ease-linear 
                                    placeholder:text-blueGray-300 focus:border-main-300 
                                    focus:shadow-lg focus:outline-none focus:ring-1 
                                    focus:ring-main-300"
                        /> */}
                        <DatePicker
                            name="fromDate"
                            selected={searchParams.fromDate || ''}
                            onChange={(date) => handleDateFromChange(date)}
                            locale="vi"
                            dateFormat="dd/MM/yyyy"
                            placeholderText={'dd/mm/yyyy'}
                            className="w-150 rounded border border-gray-300 bg-white px-3 
                                    py-2 text-sm text-blueGray-600 shadow 
                                    transition-all duration-150 ease-linear 
                                    placeholder:text-blueGray-300 
                                    focus:border-main-300 focus:shadow-lg 
                                    focus:outline-none focus:ring-1 focus:ring-main-300"
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                        />
                        <p className="font-semibold  text-slate-700">~</p>
                        <DatePicker
                            name="toDate"
                            selected={searchParams.toDate || ''}
                            onChange={(date) => handleDateToDate(date)}
                            locale="vi"
                            dateFormat="dd/MM/yyyy"
                            placeholderText={'dd/mm/yyyy'}
                            className="rounded border border-gray-300 bg-white px-3 py-2 text-sm text-blueGray-600 shadow transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:border-main-300 focus:shadow-lg focus:outline-none focus:ring-1 focus:ring-main-300"
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                        />
                        {/* <input
                            type="date"
                            name="toDate"
                            value={
                                searchParams.toDate &&
                                moment(searchParams.toDate).format('YYYY-MM-DD')
                            }
                            onChange={onChangeInputDate}
                            className="rounded border border-gray-300 bg-white px-3 py-2 text-sm text-blueGray-600 shadow transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:border-main-300 focus:shadow-lg focus:outline-none focus:ring-1 focus:ring-main-300"
                        /> */}
                    </div>
                </div>
                <div className="mt-1 p-6 overflow-x-auto px-0 pt-0 pb-2">
                    <table className="w-full min-w-[640px] table-auto border border-gray-200  shadow-lg">
                        <thead className="bg-blue-500">
                            <tr>
                                <th className="border-b border-gray-300 py-4 px-5 text-center">
                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                        STT
                                    </p>
                                </th>
                                <th className="border-b border-gray-300 py-3 px-5 text-center">
                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                        Mã đơn hàng
                                    </p>
                                </th>
                                <th className="border-b border-gray-300 py-3 px-5 text-center">
                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                        Tài nguyên sử dụng
                                    </p>
                                </th>
                                <th className="border-b border-gray-300 py-3 px-5 text-center">
                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                        Thành tiền
                                    </p>
                                </th>
                                <th className="border-b border-gray-300 py-3 px-5 text-center">
                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                        Trạng thái
                                    </p>
                                </th>
                                <th className="border-b border-gray-300 py-3 px-5 text-center">
                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                        Ngày tạo
                                    </p>
                                </th>
                                <th className="border-b border-gray-300 py-3 px-5 text-center">
                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                        Ngày hoàn thành
                                    </p>
                                </th>
                                <th className="border-b border-gray-300 py-3 px-5 text-center">
                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                        Chức năng
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionHistories?.length > 0 ? (
                                transactionHistories.map((item, index) => {
                                    let totalGuestAfterPay = 0;
                                    let totalStorageAfterPay = 0;
                                    item.detail.map((x) => {
                                        totalGuestAfterPay += x.event_guest * x.quantity;
                                        totalStorageAfterPay += x.file_storage * x.quantity;
                                    });
                                    return (
                                        <tr className={`${index % 2 !== 0 && 'bg-gray-100'} `}>
                                            <td className="py-3 px-5 border-b border-blue-gray-50">
                                                <div className="flex justify-center items-center gap-4">
                                                    <p className="block antialiased font-sans text-sm leading-normal text-gray-600">
                                                        {index + 1 + (page - 1) * limit}
                                                    </p>
                                                </div>
                                            </td>
                                            <td className="py-3 px-5 border-b border-blue-gray-50">
                                                <div className="flex justify-center items-center gap-4">
                                                    <div>
                                                        <p className="block antialiased font-sans text-sm leading-normal font-medium text-gray-600">
                                                            {item.order_alias_string +
                                                                item.order_alias_number
                                                                    ?.toString()
                                                                    ?.padStart(5, '0')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="py-3 px-5 border-b border-blue-gray-50">
                                                <div className={`relative flex flex-col`}>
                                                    <p className="">
                                                        <i className="text-sm mr-1.5 ml-[3px] text-blue-500 fas fa-database"></i>
                                                        <span className="text-sm">
                                                            Dung lượng:{' '}
                                                        </span>
                                                        <span className="text-[16px] text-gray-800 font-semibold">
                                                            {' '}
                                                            {totalStorageAfterPay}{' '}
                                                        </span>
                                                        <span className="text-sm text-gray-600 font-semibold">
                                                            {' '}
                                                            MB
                                                        </span>
                                                    </p>
                                                    <p className="">
                                                        <i className="text-sm mr-1 text-blue-500 fas fa-users"></i>
                                                        <span className="text-sm">Khách mời: </span>
                                                        <span className="text-[16px] text-gray-800 font-semibold">
                                                            {' '}
                                                            {totalGuestAfterPay}
                                                        </span>
                                                        <span className="text-sm text-gray-600 font-semibold">
                                                            {' '}
                                                            khách
                                                        </span>
                                                    </p>
                                                </div>
                                            </td>
                                            <td className="py-3 px-5 border-b border-blue-gray-50">
                                                <p className="block text-center antialiased font-sans text-gray-800 text-sm font-semibold">
                                                    {new Intl.NumberFormat('vi-VN').format(
                                                        item.total
                                                    )}{' '}
                                                    VNĐ
                                                </p>
                                            </td>
                                            <td className="py-3 px-5 border-b border-blue-gray-50">
                                                <div
                                                    className={`relative cursor-pointer grid mx-auto items-center font-sans uppercase whitespace-nowrap select-none text-white rounded-xl py-0.5 px-2.5 text-[11px] font-medium w-fit 
                                                                    bg-${
                                                                        STATUS_TEXT[item.status]
                                                                            .color
                                                                    }  `}
                                                    onClick={() => {
                                                        setStatusShow(item.status);
                                                        setOrderDetail(item);
                                                    }}
                                                >
                                                    <span className="">
                                                        {STATUS_TEXT[item.status].text}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="py-3 px-5 border-b border-blue-gray-50">
                                                <p className="block antialiased text-center font-sans text-xs font-semibold text-gray-600">
                                                    {
                                                        formatDatetime(item.createdAt).split(
                                                            'ngày'
                                                        )[0]
                                                    }
                                                </p>
                                                <p className="block antialiased text-center font-sans text-xs font-semibold text-gray-600">
                                                    {
                                                        formatDatetime(item.createdAt).split(
                                                            'ngày'
                                                        )[1]
                                                    }
                                                </p>
                                            </td>
                                            <td className="py-3 px-5 border-b border-blue-gray-50">
                                                {item.status === STATUS.COMPLETED && (
                                                    <>
                                                        <p className="block antialiased text-center font-sans text-xs font-semibold text-gray-600">
                                                            {
                                                                formatDatetime(
                                                                    item.updatedAt
                                                                ).split('ngày')[0]
                                                            }
                                                        </p>
                                                        <p className="block antialiased text-center font-sans text-xs font-semibold text-gray-600">
                                                            {
                                                                formatDatetime(
                                                                    item.updatedAt
                                                                ).split('ngày')[1]
                                                            }
                                                        </p>
                                                    </>
                                                )}
                                            </td>
                                            <td className="py-3 px-5 border-b border-blue-gray-50">
                                                <div className="flex justify-center items-center">
                                                    {item.status === STATUS.PAID && (
                                                        <>
                                                            <button
                                                                data-tip
                                                                data-for={`button-transaction-resendPaidEmail-${item._id}`}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#modalConfirmResendPaidEmail"
                                                                onClick={() => setDataDetail(item)}
                                                                className="text-red-600 text-[23px] mr-2"
                                                            >
                                                                <FaEnvelopeCircleCheck />
                                                            </button>
                                                            <ReactTooltip
                                                                id={`button-transaction-resendPaidEmail-${item._id}`}
                                                                place="top"
                                                                type="dark"
                                                                delayShow={100}
                                                            >
                                                                Gửi email tới quản trị viên
                                                            </ReactTooltip>
                                                        </>
                                                    )}
                                                    {item.status === STATUS.PENDING && (
                                                        <>
                                                            <button
                                                                data-tip
                                                                data-for={`button-transaction-confirm-${item._id}`}
                                                                // data-bs-toggle="modal"
                                                                // data-bs-target="#modalConfirmPaidOrder"
                                                                // onClick={() => setOrderId(item._id)}
                                                                onClick={() => {
                                                                    setIsOpenPaymentInfo(true);
                                                                    setOrderInfo(item);
                                                                }}
                                                                className="text-green-600 text-[22px] mr-1.5"
                                                            >
                                                                <MdAssignmentTurnedIn />
                                                            </button>
                                                            <ReactTooltip
                                                                id={`button-transaction-confirm-${item._id}`}
                                                                place="top"
                                                                type="dark"
                                                                delayShow={100}
                                                            >
                                                                Thông tin thanh toán
                                                            </ReactTooltip>
                                                        </>
                                                    )}
                                                    <button
                                                        data-tip
                                                        data-for={`button-transaction-resendEmail-${item._id}`}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#modalConfirmResendEmail"
                                                        onClick={() => setDataDetail(item)}
                                                        className="text-blue-500 text-[20px] mr-2"
                                                    >
                                                        <FaEnvelope />
                                                    </button>
                                                    <ReactTooltip
                                                        id={`button-transaction-resendEmail-${item._id}`}
                                                        place="top"
                                                        type="dark"
                                                        delayShow={100}
                                                    >
                                                        Gửi lại email đơn hàng
                                                    </ReactTooltip>
                                                    <button
                                                        data-tip
                                                        data-for={`button-transaction-detail-${item._id}`}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#modalTransactionHistoryDetail"
                                                        onClick={() => setDataDetail(item)}
                                                        className="text-black text-lg"
                                                    >
                                                        <FaListUl />
                                                    </button>
                                                    <ReactTooltip
                                                        id={`button-transaction-detail-${item._id}`}
                                                        place="top"
                                                        type="dark"
                                                        delayShow={100}
                                                    >
                                                        Chi tiết đơn hàng
                                                    </ReactTooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colspan="8">
                                        <p className="w-full text-gray-800 font-semibold text-center my-8">
                                            Chưa có lịch sử giao dịch nào.
                                        </p>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {transactionHistories?.length > 0 && (
                        <div className="flex mt-2">
                            <div className="flex items-center gap-[4px] ">
                                {page != 1 && (
                                    <button
                                        onClick={() => setPage(1)}
                                        className="p-1 rounded-full border-[1px] border-blue-700 text-blue-700"
                                    >
                                        <FaAngleDoubleLeft
                                            title="Chuyển về trang đầu"
                                            className="cursor-pointer text-[14px] font-medium"
                                        />
                                    </button>
                                )}
                                {paginate?.hasPrevPage && (
                                    <button
                                        onClick={() => setPage(page - 1)}
                                        className="p-1 rounded-full border-[1px] border-blue-700 text-blue-700"
                                    >
                                        <FaAngleLeft
                                            title="Chuyển về trang trước"
                                            className="cursor-pointer text-[14px]"
                                        />
                                    </button>
                                )}
                                <p className="text-[14px] text-black">
                                    Trang: {page}/{paginate.totalPages}
                                </p>
                                {paginate.hasNextPage && (
                                    <button
                                        onClick={() => setPage(page + 1)}
                                        className="p-1 rounded-full border-[1px] border-blue-700 text-blue-700"
                                    >
                                        <FaAngleRight
                                            title="Chuyển sang trang tiếp"
                                            className="cursor-pointer text-[14px]"
                                        />
                                    </button>
                                )}
                                {page != paginate.totalPages && (
                                    <button
                                        onClick={() => setPage(paginate.totalPages)}
                                        className="p-1 rounded-full border-[1px] border-blue-700 text-blue-700"
                                    >
                                        <FaAngleDoubleRight
                                            title="Chuyển đến trang cuối"
                                            className="cursor-pointer text-[14px]"
                                        />
                                    </button>
                                )}
                                <p className="text-sm text-black ml-[12px]">Hiển thị</p>
                                <div className="relative">
                                    <select
                                        onChange={(e) => {
                                            setLimit(Number(e.target.value));
                                            setPage(1);
                                        }}
                                        value={limit}
                                        className="w-full cursor-default rounded-md bg-white py-[2px] pl-2 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        tabindex="-1"
                                        role="listbox"
                                        ariaLabelledby="listbox-label"
                                        ariaActivedescendant="listbox-option-3"
                                    >
                                        <option
                                            className="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 text-sm"
                                            id="listbox-option-0"
                                            role="option"
                                            value={5}
                                        >
                                            <span className="font-normal ml-3 block truncate">
                                                5
                                            </span>
                                        </option>
                                        <option
                                            className="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 text-sm"
                                            id="listbox-option-0"
                                            role="option"
                                            value={10}
                                        >
                                            <span className="font-normal ml-3 block truncate">
                                                10
                                            </span>
                                        </option>
                                        <option
                                            className="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 text-sm"
                                            id="listbox-option-0"
                                            role="option"
                                            value={20}
                                        >
                                            <span className="font-normal ml-3 block truncate">
                                                20
                                            </span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
