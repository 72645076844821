/* eslint-disable */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import PAGE from '../config/path.route';
import modalAction from '../redux/modal/modal.action';
import { useDispatch } from 'react-redux';
import userService from '../views/user/User.service';
import { USER_ROLE } from '../config/constant/common.constant';
import Loading from '../components/Loading';
import userConstants from '../config/redux/user.redux';

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const [, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        userService
            .whoAmI()
            .then((user) => {
                if (!user?.roles?.includes(USER_ROLE.GUEST)) {
                    const updateRequest = (user) => ({
                        type: userConstants.UPDATE_USER_SUCCESS,
                        payload: user,
                    });
                    dispatch(updateRequest(user));
                }
                setIsLogin(true);
            })
            .catch((error) => {
                setIsLogin(false);
                if (error.message === 'NOT_FOUND_REFRESH_TOKEN') {
                    setTimeout(() => {
                        navigate('/');
                        dispatch(modalAction.openModalLogin());
                    }, 20);
                } else {
                    navigate(PAGE.dashboard);
                }
            });
        return () => setIsLogin(false);
    }, [dispatch, location, setSearchParams, navigate]);

    if (isLogin) {
        return children;
    } else {
        return <Loading></Loading>;
    }
    // return isLogin && children;
};

export default ProtectedRoute;
