/* eslint-disable */
import axios from './axios';

export const getAllUser = () => axios.get('/user/getAll');
export const editUser = (id, user) => axios.patch(`/user/update-info/${id}`, user);
export const analysisUser = () => axios.get('/user/analysis');
export const whoAmI = () => axios.get('/user/who-am-i');
export const forgotPassword = (payload) => axios.post('/user/forgot-password', payload);
export const checkEmail = (query) => axios.get('/user/forgot-password', { params: query });
export const checkUser = (params) => axios.get(`user/reset-password/${params}`);
export const resetPassword = (params, payload) =>
    axios.patch(`user/reset-password/${params}`, payload);
export const verifyToken = (token, user_name) => axios.post(`/user/verify/${token}`, { user_name });
export const resendVerifyEmail = (token) => axios.post(`/user/resend-verify-token/${token}`);
export const uploadAvatar = (data) =>
    axios({
        url: '/user/upload-avatar',
        method: 'PATCH',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: data,
    });
export const getLimitResource = () => axios.get('/user/limit-resource');
export const getPackageLimitResouce = () => axios.get('/user/package-limit-resource');
export const applyVoucher = (payload) => axios.post('/voucher/apply-voucher', payload);
