/* eslint-disable */
import {
    ACCESS_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
    USER_ROLE,
} from '../../config/constant/common.constant';
import {
    analysisUser,
    editUser,
    getAllUser,
    whoAmI,
    forgotPassword,
    checkUser,
    resetPassword,
    checkEmail,
    verifyToken,
    resendVerifyEmail,
    uploadAvatar,
    getLimitResource,
    getPackageLimitResouce,
    applyVoucher,
} from '../../api/user.api';

import Cookies from 'js-cookie';
import axios from 'axios';
import envConstants from '../../config/key';
import storageConstant from '../../config/constant/storage.constant';

const userService = {
    getAllUsers: async () => {
        const { data } = await getAllUser();
        return data;
    },
    editUserInfo: async (id, user) => {
        const { data } = await editUser(id, user);
        if (data.code === 200) {
            localStorage.setItem(storageConstant.USER, JSON.stringify(data.data));
            return data;
        } else {
            const error = new Error();
            error.statusCode = data.code;
            error.errorMessage = data.message;
            error.data = data.data;
            return Promise.reject(error);
        }
    },
    editUserGuestInfo: async (id, user) => {
        const { data } = await editUser(id, user);
        if (data.code === 200) {
            // localStorage.setItem(storageConstant.USER, JSON.stringify(data.data));
            return data;
        } else {
            const error = new Error();
            error.statusCode = data.code;
            error.errorMessage = data.message;
            error.data = data.data;
            return Promise.reject(error);
        }
    },
    analysis: async () => {
        const { data } = await analysisUser();
        return data;
    },
    whoAmI: async () => {
        try {
            const { data } = await whoAmI();
            const { data: user } = data;
            if (user?.roles?.includes(USER_ROLE.GUEST)) {
                localStorage.setItem(storageConstant.GUEST_USER, JSON.stringify(user));
            } else {
                if (!user.user_name) {
                    const localStorageUser = JSON.parse(localStorage.getItem(storageConstant.USER));
                    user.user_name = localStorageUser.user_name;
                }
                localStorage.setItem(storageConstant.USER, JSON.stringify(user));
            }
            return user;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    getNewAccessAndRefreshToken: async () => {
        const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);

        // If dont't have refreshToken means exp on Client (because cookie exp after 1 hours)
        if (!refreshToken) {
            localStorage.removeItem(storageConstant.USER);
            return Promise.reject(new Error('NOT_FOUND_REFRESH_TOKEN'));
        }

        return axios
            .post(envConstants.API_URL + '/user/refresh-token', {
                refreshToken,
            })
            .then(({ data }) => {
                const { accessToken, refreshToken } = data.data;
                const date = new Date();

                // AccessToken expr after 1 hour
                date.setTime(date.getTime() + 3600 * 1000);
                Cookies.set(ACCESS_TOKEN_KEY, accessToken, { expires: date });

                // RefreshToken exp after 2 day
                // date.setDate(date.getDate() + 2)
                Cookies.set(REFRESH_TOKEN_KEY, refreshToken, { expires: 1 });

                return { accessToken, refreshToken };
            })
            .catch((error) => {
                // Remove user's info in localstorage and couple token keys in cookie
                localStorage.removeItem(storageConstant.USER);
                Cookies.remove(ACCESS_TOKEN_KEY);
                Cookies.remove(REFRESH_TOKEN_KEY);

                return error;
            });
    },
    checkEmail: (email) =>
        checkEmail({ email: email })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    forgotPassword: async (email) =>
        forgotPassword({ email: email })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    checkUser: (params) =>
        checkUser(params)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    resetPassword: (params, password) =>
        resetPassword(params, { newPassword: password })
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    verifyToken: (token, user_name) =>
        verifyToken(token, user_name)
            .then((response) => Promise.resolve(response.data.data))
            .catch((err) => Promise.reject(err)),
    resendVerifyEmail: (token) =>
        resendVerifyEmail(token)
            .then((response) => Promise.resolve(response.data.data))
            .catch((err) => Promise.reject(err)),
    createFile: async (url, key) => {
        if (
            url === '' ||
            url === envConstants.SERVER_URL ||
            url.startsWith('data:text/html;base64')
        ) {
            return '';
        }
        let response = await fetch(url);
        let data = await response.blob();
        return new File([data], key + '_create_image.jpg', { type: 'image/jpeg' });
    },
    saveAvatar: async (files) => {
        const bodyFormData = new FormData();
        bodyFormData.append('key', 'avatar');
        bodyFormData.append('value', files);
        const result = await uploadAvatar(bodyFormData);
        return result?.data?.data;
    },
    getLimitResource: async () => {
        return await getLimitResource();
    },
    getPackageLimitResouce: async () => {
        return await getPackageLimitResouce();
    },
    getAdminPackages: async () => {
        return axios.get(envConstants.ADMIN_API_URL + '/package/group-by-type');
    },
    postAdminCreateOder: async (payload) => {
        return axios
            .post(envConstants.ADMIN_API_URL + '/order/public-create-order', payload)
            .then(({ data }) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    },
    postAdminGetOders: async (payload) => {
        return axios
            .post(envConstants.ADMIN_API_URL + '/order/public-get-order', payload)
            .then(({ data }) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    },
    postAdminConfirmPaidOder: async (userId, orderId) => {
        return axios
            .post(envConstants.ADMIN_API_URL + '/order/public-confirm-order', { userId, orderId })
            .then(({ data }) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    },
    getAdminListPendingOrder: async (user_id) => {
        return axios
            .post(envConstants.ADMIN_API_URL + '/order/public-list-pending-order-user', { user_id })
            .then(({ data }) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    },
    postAdminResendOrder: async (user_id, order_id) => {
        return axios
            .post(envConstants.ADMIN_API_URL + '/order/public-resend-order', { user_id, order_id })
            .then(({ data }) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    },
    postAdminResendPaidOrder: async (user_id, order_id) => {
        return axios
            .post(envConstants.ADMIN_API_URL + '/order/public-resend-paid-order', {
                user_id,
                order_id,
            })
            .then(({ data }) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    },
    getAdminListBank: async () => {
        return axios
            .get(envConstants.ADMIN_API_URL + '/bank/list')
            .then(({ data }) => Promise.resolve(data))
            .catch((err) => Promise.reject(err));
    },
    getAdminImageQRBank: (order_alias, bank_key) => {
        return `${envConstants.ADMIN_API_URL}/bank/qr-image?order_alias=${order_alias}&bank_key=${bank_key}`;
    },
    applyVoucher: async (payload) => {
        return applyVoucher(payload);
    },
};

export default userService;
