/* eslint-disable  */

import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
import PublishSummaryService from './PublishSummary.service';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// import Templates from '../events/components/Templates';
import { useParams, useNavigate } from 'react-router-dom';
import { Cropper } from 'react-mobile-cropper';
// import BaseModal from '../../../../components/BaseModal';
import PAGE from '../../../../config/path.route';
import ModalLeavingPage from '../../../events/components/ModalLeavingPage';
// import { setContentMeta } from '../../utils/MetaTag';
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async';
import 'react-mobile-cropper/dist/style.css';
import ToastNotify from '../../../../utils/Toast';
import EventsService from '../../../events/Events.service';
import SummarySkeleton from './SummarySkeleton';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronCircleDown, FaChevronCircleUp, FaTimes, FaSave } from 'react-icons/fa';
import { validateSizeOfFile } from '../../../../utils/common';
import LoadingButtonIcon from '../../../../components/LoadingButton/LoadingButtonIcon';
import envConstants from '../../../../config/key';
import LogoUploader from './components/LogoUploader';
import ShortcutMenu from '../../components/ShortcutMenu';
import Regex from '../../../../utils/Regex';

import QuestionTitle from '../publish.question/QuestionTitle';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import moment from 'moment';
import Header from '../publish.sub/components/Header';
import { useSelector } from 'react-redux';

registerLocale('vi', vi);
const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // infoParty
    if (dataCopy?.infoParty) {
        arrCopy = dataCopy.infoParty;
        keyName = 'infoParty';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(JSON.parse(arrCopy));
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

const regexStr = /^[^\\(\\)~`#$%^&*=|\?\/<>\\*]+$/g;

const EnventSummary = ({
    normalObj,
    templateAlias,
    setLoad,
    load,
    isExpired,
    setIsEditShortcut,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showOtherInfo, setShowOtherInfo] = useState(false);
    const schema = yup.object().shape({
        titleEvent: yup
            .string()
            .required('Vui lòng nhập tiêu để!')
            .max(200, 'Tiêu đề không được quá 200 ký tự!'),
        nameEvent: yup
            .string()
            .required('Vui lòng nhập tên sự kiện!')
            .max(200, 'Tên sự kiện không được quá 200 ký tự!'),
        invitation: yup
            .string()
            .required('Vui lòng nhập lời mời!')
            .max(200, 'Lời mời không được quá 200 ký tự!'),
        nameTemplate: yup
            .string()
            .trim()
            .required('Vui lòng nhập tên thiệp của bạn!')
            .max(200, 'Tên thiệp không vượt quá 200 ký tự!'),
        linkGgMap: yup.string().when({
            is: (exists) => !!exists,
            then: (rule) => rule.matches(Regex.url, 'Đường dẫn không hợp lệ!'),
        }),
        timeHold: yup.string().required('Vui lòng chọn thời gian!'),
        dateHold: yup
            .string()
            .nullable(true)
            .required('Vui lòng chọn ngày!')
            .transform((curr, orig) => (orig === '' ? null : curr)),
    });
    const [content, setContent] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [isOpenLeavinModal, setOpenLeavinModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [tempImg, setTempImg] = useState(null);
    const [isDeleteLogo, setDeleteLogo] = useState(false);
    const [checkDeleteLogo, setCheckDeleteLogo] = useState(false);
    const [selectedDate, setSelectedDate] = useState(
        content?.dateHold ? new Date(content.dateHold) : null
    );

    const handleDelete = () => {
        setDeleteLogo(true);
        setCheckDeleteLogo(true);
        setIsEdit(true);
        setContent({
            ...content,
            logo: [],
        });
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        triggerValidation,
        // watch
        setValue,
        clearErrors,
    } = useForm({ resolver: yupResolver(schema), mode: 'onSubmit', reValidateMode: 'onChange' });

    const onChangeInput = (event) => {
        // if (normalObj[event.target.name] !== event.target.value) {
        setIsEdit(true);
        setIsEditShortcut(true);
        // } else {
        //     setIsEdit(false);
        // }
        setContent({ ...content, [event.target.name]: event.target.value });
    };
    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        setTempImg(null);
        reader.addEventListener('load', () => {
            const image = reader.result;
            setTempImg(image);
            setOpenModal(true);
        });
        reader.readAsDataURL(val);
    };
    useEffect(() => {
        setContent({
            _id: normalObj._id,
            titleEvent: normalObj?.titleEvent?.length > 0 ? normalObj.titleEvent : 'Thư mời',
            nameEvent: normalObj.nameEvent,
            invitation:
                normalObj?.invitation?.length > 0 ? normalObj.invitation : 'Kính mời tham dự',
            dateHold: normalObj?.dateHold,
            groupName: normalObj?.groupName,
            timeHold: normalObj?.timeHold,
            groupNameBrief: normalObj?.groupNameBrief,
            addressHold: normalObj?.addressHold,
            placeHold: normalObj?.placeHold,
            nameSupporter: normalObj?.nameSupporter,
            phoneSupporter: normalObj?.phoneSupporter,
            nameTemplate: normalObj?.nameTemplate,
            logo: normalObj.logo ? process.env.REACT_APP_SERVER_URL + normalObj.logo : null,
            linkGgMap: normalObj?.linkGgMap ? normalObj?.linkGgMap : '',
        });
        if (normalObj?.titleEvent?.length == 0 || normalObj?.invitation?.length == 0) {
            setIsEdit(true);
        }
        setValue(
            'titleEvent',
            normalObj?.titleEvent?.length > 0 ? normalObj.titleEvent : 'Thư mời'
        );
        setValue('nameEvent', normalObj.nameEvent);
        setValue(
            'invitation',
            normalObj?.invitation?.length > 0 ? normalObj.invitation : 'Kính mời tham dự'
        );
        setValue('nameTemplate', normalObj.nameTemplate);
        setCheckDeleteLogo(false);
        setValue('dateHold', normalObj.dateHold);
        setValue('timeHold', normalObj.timeHold);
    }, [normalObj, setValue]);
    const submitHandle = async () => {
        setOpenLeavinModal(true);
    };
    const submitForm = async () => {
        delete content?.logo;
        // PublishSummaryService.saveContent(content._id, content)
        //     .then(({ data }) => {
        //         ToastNotify.Success('Lưu thành công');
        //         const event = data.data;
        //         if (event?.is_public) {
        //             screenShot(event._id, event?.url_slug);
        //         }
        //     })
        //     .catch(() => ToastNotify.Error('Đã có lỗi xảy ra.'))
        //     .finally(() => {
        //         setLoad(!load);
        //         setIsEdit(false);
        //     });
        const promises = [
            PublishSummaryService.saveContent(content._id, {
                ...content,
                ...(checkDeleteLogo ? { logo: [] } : {}),
            }),
            EventsService.updateEventTemplate(content._id, { name: content.nameTemplate }),
        ];
        Promise.all(promises)
            .then((responses) => {
                const arrEvents = responses.map(({ data }) => data.data);
                const [event] = arrEvents;
                ToastNotify.Success('Lưu thành công');
                // if (event?.is_public) {
                //     screenShot(event._id, event?.url_slug);
                // }
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra'))
            .finally(() => {
                setLoad(!load);
                setIsEdit(false);
                setIsEditShortcut(false);
            });
    };

    const handleShowOtherInfo = () => {
        setShowOtherInfo(!showOtherInfo);
    };

    const handleDateChange = (date) => {
        setIsOpen(false);
        clearErrors('dateHold');
        register('dateHold');
        setValue('dateHold', date);
        onChangeInput({
            target: { name: 'dateHold', value: date ? moment(date).format('YYYY-MM-DD') : '' },
        });
    };

    return (
        <form
            className="flex flex-col"
            onSubmit={(event) => {
                event.preventDefault();
                handleSubmit(submitHandle)(event).catch((err) => {
                    console.log('handleSubmit err = ', err);
                });
            }}
            noValidate={true}
        >
            <div className="mb-4 rounded-lg bg-white shadow">
                <div className="flex items-center justify-between border-gray-200 pb-0 p-4 ">
                    <p className="mb-0 font-bold text-gray-900">
                        Tên thiệp <span className="text-red-500 font-normal">(*)</span>
                    </p>
                </div>
                <div className="flex flex-col gap-4 p-4 pt-1">
                    <div className="block w-full">
                        <div className="flex w-full">
                            <div className="w-full">
                                <input
                                    type="text"
                                    name="nameTemplate"
                                    value={content?.nameTemplate}
                                    onInput={onChangeInput}
                                    placeholder="Nhập tên thiệp của bạn"
                                    disabled={isExpired}
                                    {...register('nameTemplate')}
                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                />
                            </div>
                        </div>
                        <span className="text-xs font-medium text-red-500">
                            {errors.nameTemplate && errors.nameTemplate?.message}
                        </span>
                    </div>
                </div>
            </div>
            <div className="mb-4 scroll-mt-[100px] rounded-lg bg-white shadow">
                <div className="flex items-center justify-between border-b border-gray-200 p-4 ">
                    <p className="mb-0 text-[16px] font-bold text-gray-900">Thông tin sự kiện</p>
                </div>
                <div className="flex flex-col gap-4 p-4">
                    <QuestionTitle keyQues={'main_info_ev'} />
                    <div className="w-full ">
                        <label className="block w-full">
                            <span className="mb-5 text-[14px] font-normal">
                                Tiêu đề <span className="text-red-500">(*)</span>
                            </span>
                            <div className="flex w-full">
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="titleEvent"
                                        value={content?.titleEvent}
                                        onInput={onChangeInput}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                        {...register('titleEvent')}
                                        placeholder={`VD: "Tham dự"`}
                                        disabled={isExpired}
                                    />
                                </div>
                            </div>
                            <span className="text-xs font-medium text-red-500">
                                {errors.titleEvent && errors.titleEvent?.message}
                            </span>
                        </label>
                    </div>
                    <div className="w-full">
                        <div className="block w-full">
                            <span className="mb-5 text-[14px] font-normal">
                                Lời mời <span className="text-red-500">(*)</span>
                            </span>
                            <div className="flex w-full">
                                <div className="w-full ">
                                    <input
                                        type="text"
                                        name="invitation"
                                        value={content?.invitation}
                                        onInput={onChangeInput}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                        {...register('invitation')}
                                        placeholder='VD: "Kính mời tham dự"'
                                        disabled={isExpired}
                                    />
                                </div>
                            </div>
                            <span className="text-xs font-medium text-red-500">
                                {errors.invitation && errors.invitation?.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="block w-full">
                            <span className="mb-5 text-[14px] font-normal">
                                Tên sự kiện <span className="text-red-500">(*)</span>
                            </span>
                            <div className="flex w-full">
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="nameEvent"
                                        value={content?.nameEvent}
                                        onInput={onChangeInput}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                        {...register('nameEvent')}
                                        placeholder='VD: "Lễ kỷ niệm"'
                                        disabled={isExpired}
                                    />
                                </div>
                            </div>
                            <span className="text-xs font-medium text-red-500">
                                {errors.nameEvent && errors.nameEvent?.message}
                            </span>
                        </div>
                    </div>

                    <div className="w-full ">
                        <label className="block w-full">
                            <span className="mb-5 text-[14px] font-normal">
                                Thời gian <span className="text-red-500">(*)</span>
                            </span>
                            <div className="flex w-full">
                                <div className="mr-2 w-1/2 flex flex-col gap-1">
                                    <input
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                        id="time"
                                        type="time"
                                        name="timeHold"
                                        value={content?.timeHold}
                                        onKeyDown={(event) => event.preventDefault()}
                                        placeholder={content?.timeHold}
                                        disabled={isExpired}
                                        onInput={onChangeInput}
                                        step="60"
                                        {...register('timeHold')}
                                    />
                                    <span className="text-xs font-medium text-red-500">
                                        {errors.timeHold && errors.timeHold?.message}
                                    </span>
                                </div>
                                <div className="w-1/2 flex flex-col gap-1">
                                    <DatePicker
                                        onInputClick={() => setIsOpen(false)}
                                        shouldCloseOnSelect={true}
                                        name="dateHold"
                                        selected={content?.dateHold}
                                        onChange={(date) => handleDateChange(date)}
                                        minDate={new Date()}
                                        locale="vi"
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={'dd/mm/yyyy'}
                                        disabled={isExpired}
                                        className={`mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white ${
                                            isExpired ? 'disabled:bg-gray-100' : ''
                                        } p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1`}
                                    />
                                    <span className="text-xs font-medium text-red-500">
                                        {errors.dateHold && errors.dateHold?.message}
                                    </span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="w-full">
                        <div className="block w-full">
                            <span className="mb-5 text-sm font-normal">Địa điểm</span>
                            <div className="flex w-full">
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="placeHold"
                                        value={content?.placeHold}
                                        onInput={onChangeInput}
                                        placeholder={`VD: "Nhà hàng A"`}
                                        disabled={isExpired}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="block w-full">
                            <span className="mb-5 text-sm font-normal">Địa chỉ</span>
                            <div className="flex w-full">
                                <div className="w-full ">
                                    <input
                                        type="text"
                                        name="addressHold"
                                        value={content?.addressHold}
                                        onInput={onChangeInput}
                                        placeholder={`VD: "Nhân Chính, Thanh Xuân, Hà Nội"`}
                                        disabled={isExpired}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="block w-full">
                            <span className="mb-5 text-sm font-normal">Đường dẫn bản đồ</span>
                            <div className="flex w-full">
                                <div className="w-full ">
                                    <input
                                        type="text"
                                        name="linkGgMap"
                                        value={content?.linkGgMap}
                                        onInput={onChangeInput}
                                        placeholder={`VD: "https://maps.app.goo.gl/V7iKpMMo5sjrTiRr9"`}
                                        disabled={isExpired}
                                        className="mt-2 block w-full rounded-[0.375rem] 
                                            border border-gray-300 bg-white 
                                            disabled:bg-gray-100 p-3 text-sm
                                             text-blueGray-600 transition-all 
                                             duration-150 ease-linear placeholder:text-blueGray-300 
                                             focus:outline-none focus:ring-1"
                                        {...register('linkGgMap')}
                                    />
                                </div>
                            </div>
                            <span className="text-xs font-medium text-red-500">
                                {errors.linkGgMap && errors.linkGgMap?.message}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <LogoUploader
                eventId={normalObj._id}
                isExpired={isExpired}
                isDelete={isDeleteLogo}
                onDelete={handleDelete}
                setDelete={setDeleteLogo}
            />

            <div className={(isEdit ? '' : 'hidden') + ' py-6 md:px-12 lg:z-[40]'}></div>
            {!isExpired && (
                <div
                    className={
                        (isEdit ? 'bottom-0 opacity-100' : '-bottom-80 opacity-0') +
                        ' fixed inset-x-0 z-[40] bg-gray-50 transition-all duration-500 md:px-6 lg:z-[40]'
                    }
                >
                    <div className="flex items-center justify-between gap-3 border-t border-gray-300 px-6 py-4 md:px-0">
                        <p>Chưa lưu !</p>
                        <div className="flex gap-3">
                            <button
                                type="button"
                                className={
                                    'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-100 px-6 py-2 text-center font-semibold text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-200 '
                                }
                                onClick={() => {
                                    setIsEdit(false);
                                    setIsEditShortcut(false);
                                    setLoad(!load);
                                }}
                            >
                                <FaTimes />
                                Hủy bỏ
                            </button>
                            <button
                                type="submit"
                                className={
                                    'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-500 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 '
                                }
                            >
                                <FaSave />
                                Hoàn tất
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <ModalLeavingPage
                saveChanged={submitForm}
                isOpen={isOpenLeavinModal}
                confirmNavigation={() => {
                    setOpenLeavinModal(false);
                }}
                funToggle={() => {
                    setOpenLeavinModal(false);
                }}
            />
        </form>
    );
};

const WeddingSummary = ({
    normalObj,
    templateAlias,
    setLoad,
    load,
    isExpired,
    setKeyQues,
    setIsEditShortcut,
}) => {
    const schema = yup.object().shape({
        timeCeremony: yup.string().required('Vui lòng chọn thời gian!'),
        dateCeremony: yup
            .string()
            .nullable(true)
            .required('Vui lòng chọn ngày!')
            .transform((curr, orig) => (orig === '' ? null : curr)),
        placeCeremony: yup
            .string()
            .trim()
            .required('Vui lòng nhập địa điểm!')
            .matches(regexStr, 'Vui lòng nhập địa điểm hợp lệ!')
            .max(200, 'Địa điểm không vượt quá 200 ký tự!'),
        linkGgMap: yup.string().trim().matches(Regex.linkGgMap, {
            excludeEmptyString: true,
            message: 'Đường dẫn không hợp lệ',
        }),
        // .required('Chưa nhập địa điểm!'),
        addressCeremony: yup
            .string()
            .trim()
            .required('Vui lòng nhập địa chỉ cụ thể!')
            .matches(regexStr, 'Vui lòng nhập địa chỉ hợp lệ!')
            .max(200, 'Địa chỉ cụ thể không vượt quá 200 ký tự!'),
        nameSupporter: yup
            .string()
            .trim()
            .matches(regexStr, {
                excludeEmptyString: true,
                message: 'Vui lòng nhập tên hợp lệ!',
            })
            .max(50, 'Tên không vượt quá 50 ký tự!'),
        nameTemplate: yup
            .string()
            .trim()
            .required('Vui lòng nhập tên thiệp của bạn!')
            // .matches(regexStr, {
            //     excludeEmptyString: true,
            //     message: 'Vui lòng nhập tên hợp lệ!',
            // })
            .max(200, 'Tên không vượt quá 200 ký tự!'),
        phoneSupporter: yup
            .string()
            .trim()
            .matches(/([\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/, {
                excludeEmptyString: true,
                message: 'Số điện thoại không hợp lệ!',
            }),
    });

    const [content, setContent] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isOpenLeavinModal, setOpenLeavinModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleChanges = (newValue) => {
        setValue(newValue);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        // watch
        setValue,
        trigger,
        clearErrors,
    } = useForm({ resolver: yupResolver(schema), mode: 'onSubmit', reValidateMode: 'onChange' });

    const onChangeInput = (event) => {
        // trigger('link');
        // if (normalObj[event.target.name] !== event.target.value) {
        setIsEdit(true);
        setIsEditShortcut(true);
        // } else {
        //     setIsEdit(false);
        // }
        setContent({ ...content, [event.target.name]: event.target.value });
    };
    useEffect(() => {
        setContent({
            _id: normalObj._id,
            dateCeremony: normalObj.dateCeremony,
            timeCeremony: normalObj.timeCeremony,
            addressCeremony: normalObj.addressCeremony,
            placeCeremony: normalObj.placeCeremony,
            linkGgMap: normalObj.linkGgMap,
            nameSupporter: normalObj.nameSupporter,
            phoneSupporter: normalObj.phoneSupporter,
            nameTemplate: normalObj.nameTemplate,
        });
        setValue('dateCeremony', normalObj.dateCeremony);
        setValue('timeCeremony', normalObj.timeCeremony);
        setValue('addressCeremony', normalObj.addressCeremony);
        setValue('placeCeremony', normalObj.placeCeremony);
        setValue('linkGgMap', normalObj.linkGgMap);
        setValue('nameSupporter', normalObj.nameSupporter);
        setValue('phoneSupporter', normalObj.phoneSupporter);
        setValue('nameTemplate', normalObj.nameTemplate);
    }, [normalObj, setValue]);

    const submitForm = async () => {
        // PublishSummaryService.saveContent(content._id, content)
        //     .then(({ data }) => {
        //         ToastNotify.Success('Lưu thành công');
        //         const event = data.data;
        //         if (event?.is_public) {
        //             screenShot(event._id, event.url_slug);
        //         }
        //     })
        //     .catch(() => ToastNotify.Error('Đã có lỗi xảy ra.'))
        //     .finally(() => {
        //         setLoad(!load);
        //         setIsEdit(false);
        //     });
        const promises = [
            PublishSummaryService.saveContent(content._id, content),
            EventsService.updateEventTemplate(content._id, { name: content.nameTemplate }),
        ];
        Promise.all(promises)
            .then((responses) => {
                const arrEvents = responses.map(({ data }) => data.data);
                const [event] = arrEvents;
                ToastNotify.Success('Lưu thành công');
                // if (event?.is_public) {
                //     screenShot(event._id, event.url_slug);
                // }
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'))
            .finally(() => {
                setLoad(!load);
                setIsEdit(false);
                setIsEditShortcut(false);
            });
    };
    const submitHandle = async () => {
        setOpenLeavinModal(true);
    };
    const handleDateChange = (date) => {
        setIsOpen(false);
        clearErrors('dateCeremony');
        register('dateCeremony');
        setValue('dateCeremony', date);
        onChangeInput({
            target: { name: 'dateCeremony', value: date ? moment(date).format('YYYY-MM-DD') : '' },
        });
    };

    // const [selectedTime, setSelectedTime] = useState(setHours(setMinutes(new Date(), 0), 0));
    const [selectedTime, setSelectedTime] = useState(null);

    const handleChange = (time) => {
        setSelectedTime(time);
        setIsOpen(false);
    };

    return (
        <form
            className=" flex flex-col"
            onSubmit={(event) => {
                event.preventDefault();
                handleSubmit(submitHandle)(event).catch((err) => {
                    // ToastNotify.Error(err.message);
                    console.log('handleSubmit err = ', err);
                });
            }}
            // noValidate={true}
        >
            <div className="mb-5 rounded-lg bg-white shadow">
                <div className="flex items-center justify-between border-gray-200 pb-0 p-4 md:p-6 md:pb-0">
                    <p className="mb-0 font-bold text-gray-900">
                        Tên thiệp <span className="text-red-500 font-normal">(*)</span>
                    </p>
                </div>
                <div className="flex flex-col gap-4 p-4 pt-1">
                    <div className="block w-full">
                        <div className="flex w-full">
                            <div className="w-full">
                                <input
                                    type="text"
                                    name="nameTemplate"
                                    value={content?.nameTemplate}
                                    onInput={onChangeInput}
                                    placeholder={`VD: "Thiệp cưới của A-B"`}
                                    disabled={isExpired}
                                    {...register('nameTemplate')}
                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                />
                            </div>
                        </div>
                        <span className="text-xs font-medium text-red-500">
                            {errors.nameTemplate && errors.nameTemplate?.message}
                        </span>
                    </div>
                </div>
            </div>
            <div className="mb-5 scroll-mt-[100px] rounded-lg bg-white shadow">
                <div className="flex items-center justify-between border-b border-gray-200 p-4 md:p-6">
                    <p className="mb-0 text-[16px] font-bold text-gray-900">Thông tin chính</p>
                </div>
                <div className="flex flex-col gap-4 p-4">
                    <QuestionTitle keyQues={'main_info_wed'} />
                    <div className="w-full ">
                        <label className="block w-full">
                            <span className="mb-5 text-[14px] font-normal">
                                Thời gian <span className="text-red-500">(*)</span>
                            </span>
                            <div className="flex w-ful">
                                <div className="mr-2 w-1/2">
                                    <input
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                        id="time"
                                        type="time"
                                        name="timeCeremony"
                                        value={content?.timeCeremony}
                                        onKeyDown={(event) => event.preventDefault()}
                                        placeholder={content?.timeCeremony}
                                        disabled={isExpired}
                                        onInput={onChangeInput}
                                        step="60"
                                        {...register('timeCeremony')}
                                    />

                                    <span className="text-xs font-medium text-red-500">
                                        {errors.timeCeremony && errors.timeCeremony?.message}
                                    </span>
                                </div>

                                <div className="w-1/2">
                                    <DatePicker
                                        onInputClick={() => setIsOpen(false)}
                                        shouldCloseOnSelect={true}
                                        name="dateCeremony"
                                        selected={content?.dateCeremony}
                                        onChange={(date) => handleDateChange(date)}
                                        minDate={new Date()}
                                        locale="vi"
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={'dd/mm/yyyy'}
                                        disabled={isExpired}
                                        className={`mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white ${
                                            isExpired ? 'disabled:bg-gray-100' : ''
                                        } p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1`}
                                        // {...register('dateCeremony')}
                                    />
                                    <span className="text-xs font-medium text-red-500">
                                        {errors.dateCeremony && errors.dateCeremony?.message}
                                    </span>{' '}
                                    {/* <input
                                        type="date"
                                        min={new Date()
                                            .toISOString()
                                            // eslint-disable-next-line no-magic-numbers
                                            .slice(0, 10)}
                                        name="dateCeremony"
                                        value={content?.dateCeremony}
                                        placeholder={content?.dateCeremony}
                                        onKeyDown={(event) => event.preventDefault()}
                                        disabled={isExpired}
                                        onInput={onChangeInput}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100  p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                        {...register('dateCeremony')}
                                    /> */}
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="w-full">
                        <div className="block w-full">
                            <span className="mb-5 text-sm font-normal">
                                Địa điểm <span className="text-red-500">(*)</span>
                            </span>
                            <div className="flex w-full">
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="placeCeremony"
                                        value={content?.placeCeremony}
                                        onInput={onChangeInput}
                                        placeholder={`VD: "Tư gia nhà trai"`}
                                        disabled={isExpired}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100  p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                        {...register('placeCeremony')}
                                    />
                                </div>
                            </div>
                            <span className="text-xs font-medium text-red-500">
                                {errors.placeCeremony && errors.placeCeremony?.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="block w-full">
                            <span className="mb-5 text-sm font-normal">
                                Địa chỉ <span className="text-red-500">(*)</span>
                            </span>
                            <div className="flex w-full">
                                <div className="w-full ">
                                    <input
                                        type="text"
                                        name="addressCeremony"
                                        value={content?.addressCeremony}
                                        onInput={onChangeInput}
                                        placeholder={`VD: "Nhân Chính, Thanh Xuân, Hà Nội"`}
                                        disabled={isExpired}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                        {...register('addressCeremony')}
                                    />
                                </div>
                            </div>
                            <span className="text-xs font-medium text-red-500">
                                {errors.addressCeremony && errors.addressCeremony?.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="block w-full">
                            <span className="mb-5 text-sm font-normal">Đường dẫn bản đồ</span>
                            <div className="flex w-full">
                                <div className="w-full ">
                                    <input
                                        type="text"
                                        name="linkGgMap"
                                        value={content?.linkGgMap}
                                        onInput={onChangeInput}
                                        placeholder={`VD: "https://maps.app.goo.gl/V7iKpMMo5sjrTiRr9"`}
                                        disabled={isExpired}
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                        {...register('linkGgMap')}
                                    />
                                </div>
                            </div>
                            <span className="text-xs font-medium text-red-500">
                                {errors.linkGgMap && errors.linkGgMap?.message}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-5 scroll-mt-[100px] rounded-lg bg-white shadow">
                <div className="flex items-center justify-between border-b border-gray-200 p-4 md:p-6">
                    <p className="mb-0 font-bold text-gray-900">Thông tin người hỗ trợ</p>
                </div>
                <div className="flex flex-col gap-4 p-4">
                    <QuestionTitle keyQues={'supporter'} />
                    <div className="block w-full">
                        <span className="mb-5 text-sm font-normal">Họ và tên</span>
                        <div className="flex w-full">
                            <div className="w-full">
                                <input
                                    type="text"
                                    name="nameSupporter"
                                    value={content?.nameSupporter}
                                    onInput={onChangeInput}
                                    placeholder={`VD: "Nguyễn Văn A"`}
                                    disabled={isExpired}
                                    {...register('nameSupporter')}
                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                />
                            </div>
                        </div>
                        <span className="text-xs font-medium text-red-500">
                            {errors.nameSupporter && errors.nameSupporter?.message}
                        </span>
                    </div>
                    <div className="block w-full">
                        <span className="mb-5 text-sm font-normal">Số điện thoại</span>
                        <div className="flex w-full">
                            <div
                                className="w-full"
                                // onClick={() => {
                                //     setHighlightContext('addressBride');
                                // }}
                            >
                                <input
                                    type="text"
                                    name="phoneSupporter"
                                    disabled={isExpired}
                                    value={content?.phoneSupporter}
                                    onInput={onChangeInput}
                                    placeholder={`VD: "0124567809"`}
                                    {...register('phoneSupporter')}
                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100  p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                />
                            </div>
                        </div>
                        <span className="text-xs font-medium text-red-500">
                            {errors.phoneSupporter && errors.phoneSupporter?.message}
                        </span>
                    </div>
                </div>
            </div>

            {!isExpired && (
                <div
                    className={
                        (isEdit ? 'bottom-0 opacity-100' : '-bottom-80 opacity-0') +
                        ' fixed inset-x-0 z-[40] bg-gray-50 transition-all duration-500 md:px-6 lg:z-[40]'
                    }
                >
                    <div className="flex items-center justify-between gap-3 border-t border-gray-300 px-6 py-4 md:px-0">
                        <p></p>
                        <div className="flex gap-3">
                            <button
                                type="button"
                                className={
                                    'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-100 px-6 py-2 text-center font-semibold text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-200 '
                                }
                                onClick={() => {
                                    setIsEdit(false);
                                    setIsEditShortcut(false);
                                    setLoad(!load);
                                }}
                            >
                                <FaTimes />
                                Hủy bỏ
                            </button>
                            <button
                                type="submit"
                                className={
                                    'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-500 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 '
                                }
                            >
                                <FaSave />
                                Hoàn tất
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <ModalLeavingPage
                saveChanged={submitForm}
                isOpen={isOpenLeavinModal}
                confirmNavigation={() => {
                    setOpenLeavinModal(false);
                }}
                funToggle={() => {
                    setOpenLeavinModal(false);
                }}
            />
        </form>
    );
};

export default function PublishSummary() {
    const navigate = useNavigate();
    const { eventId, url_guest: urlGuest } = useParams();
    const [isWedding, setWedding] = useState(false);
    const [normalObj, setNormalObj] = useState({});
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [templateAlias, setTemplateAlias] = useState('');
    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
    });
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const [isEditShortcut, setIsEditShortcut] = useState(false);
    const [event, setEvent] = useState();
    const { loggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        setLoading(true);
        if (eventId) {
            // const payload = { slug: eventId };
            // if (urlGuest) {
            //     payload.guest = urlGuest;
            // }
            // PublishSummaryService.getPublic(payload)
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    setEvent(data);
                    //     const { templateAlias, formData, guest, title, thumbnail, data, eventConfig
                    // } =
                    //         res;
                    const title = data.form_data.titleMeta || 'ChungVui';
                    const thumbnail = data.thumbnail_url;
                    const templateAlias = data.template.alias;

                    const formData = data.form_data;
                    console.log('formData -----', formData);
                    const jsonData = parseJsonData({ ...data, ...formData });
                    setIsExpiredEvent(data?.isExpired);
                    // setContentMeta({ title, image: thumbnail })
                    setValueMeta({
                        title,
                        thumbnail,
                    });
                    setTemplateAlias(templateAlias);
                    // setGuest(guest);
                    if (templateAlias.includes('wedding')) {
                        setWedding(true);
                        setNormalObj({
                            _id: jsonData?._id,
                            dateCeremony: jsonData?.dateCeremony,
                            timeCeremony: jsonData?.timeCeremony,
                            addressCeremony: jsonData?.addressCeremony,
                            placeCeremony: jsonData?.placeCeremony,
                            linkGgMap: jsonData?.linkGgMap,
                            nameSupporter: jsonData?.nameSupporter,
                            phoneSupporter: jsonData?.phoneSupporter,
                            nameTemplate: jsonData?.name,
                        });
                    } else {
                        setNormalObj({
                            _id: jsonData?._id,
                            titleEvent: jsonData?.titleEvent ?? null,
                            nameEvent: jsonData?.nameEvent,
                            invitation: jsonData?.invitation,
                            logo: jsonData?.logo ? jsonData?.logo[0] : null,
                            dateHold: jsonData?.dateHold,
                            timeHold: jsonData?.timeHold,
                            addressHold: jsonData?.addressHold,
                            groupName: jsonData?.groupName,
                            groupNameBrief: jsonData?.groupNameBrief,
                            placeHold: jsonData?.placeHold,
                            nameSupporter: jsonData?.nameSupporter,
                            phoneSupporter: jsonData?.phoneSupporter,
                            nameTemplate: jsonData?.name,
                            linkGgMap: jsonData?.linkGgMap ?? '',
                        });
                    }

                    // setEventConfig(eventConfig);
                })
                .catch((error) => {
                    console.log(error);
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 400);
                });
        }
    }, [eventId, urlGuest, navigate, templateAlias, load]);

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    const [keyQues, setKeyQues] = useState('');

    return (
        <div>
            <Helmet>
                <title>{valueMeta.title + ' | ' + 'Thông tin chung'}</title>
                <meta property="og:image" content={valueMeta.thumbnail} />
            </Helmet>
            <div className={'relative h-[100dvh] w-[100vw] bg-gray-50 text-black'}>
                <Header event={event} isExpired={isExpiredEvent} />
                <div className="h-full px-[1rem] py-[1.5rem] mt-[60px]">
                    <div className="w-full flex justify-evenly">
                        <div className="lg:flex hidden relative w-[250px]">
                            <TimelineShortcutMenu screen={'summary'} isEdit={isEditShortcut} />
                        </div>
                        <div className=" relative min-h-[300px] w-full  mb-3 md:col-span-2 lg:w-[48rem] 2xl:w-[60rem] p-2 border border-gray-200 rounded-md">
                            <p className="font-bold text-black text-lg ">Thông tin chung</p>
                            {loading && <SummarySkeleton />}
                            {!loading && isWedding && (
                                <WeddingSummary
                                    normalObj={normalObj}
                                    navigate={navigate}
                                    setLoad={setLoad}
                                    templateAlias={templateAlias}
                                    load={load}
                                    isExpired={isExpiredEvent}
                                    setKeyQues={setKeyQues}
                                    setIsEditShortcut={setIsEditShortcut}
                                />
                            )}
                            {!loading && !isWedding && (
                                <EnventSummary
                                    normalObj={normalObj}
                                    setLoad={setLoad}
                                    navigate={navigate}
                                    templateAlias={templateAlias}
                                    load={load}
                                    isExpired={isExpiredEvent}
                                    setIsEditShortcut={setIsEditShortcut}
                                />
                            )}
                        </div>
                        <div className="lg:flex hidden relative w-[250px]">
                            <Products />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const screenShot = (eventId, eventUrlSlug) => {
    // Screen short
    const linkPage = envConstants.CLIENT_URL + eventUrlSlug;
    const linkThumbnail = envConstants.CLIENT_URL + 'thumbnail/' + eventUrlSlug;
    const location = 'events/' + eventId;
    const promises = [
        EventsService.getScreenshot(linkPage, location),
        EventsService.getScreenshot(linkThumbnail, location),
    ];
    Promise.all(promises)
        .then((responses) => {
            const arrPathData = responses.map(({ data }) => data.path);
            const [image, thumbnail] = arrPathData;
            const payload = {
                image: image,
                thumbnail: thumbnail,
            };
            return payload;
        })
        .then((formData) => EventsService.updateEventTemplate(eventId, formData))
        .catch((error) => console.log(error));
};
