/* eslint-disable */

import { useEffect, useRef, useState } from 'react';
import BaseModal from '../../../../../components/BaseModal';
import LoadingButtonIcon from '../../../../../components/LoadingButton/LoadingButtonIcon';
import ModalLeavingPage from '../../../../events/components/ModalLeavingPage';
import { Cropper } from 'react-mobile-cropper';
import PublishSummaryService from '../PublishSummary.service';
import { validateSizeOfFile } from '../../../../../utils/common';
import EventsService from '../../../../events/Events.service';
import envConstants from '../../../../../config/key';
import ToastNotify from '../../../../../utils/Toast';
import { FaPen, FaSave } from 'react-icons/fa';

const CropperModal = ({ defaultsrc, eventId, load, setLoad, setOpen }) => {
    const cropperRef = useRef(null);
    const [image, setImage] = useState(defaultsrc);
    const [isOpenModal, setOpenModal] = useState(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const toastLoadingId = useRef(null);

    const submitHandle = async () => {
        setIsLoadingBtn(true);
        const file = await PublishSummaryService.createFile(
            cropperRef.current.getCanvas()?.toDataURL('image/jpeg', 1),
            'img'
        );
        setIsLoadingBtn(false);
        if (
            validateSizeOfFile(
                file,
                20,
                'Dung lượng ảnh sau khi chỉnh sửa không được vượt quá 20Mb'
            )
        ) {
            setOpenModal(true);
        }
    };
    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImage(image);
        });
        reader.readAsDataURL(val);
    };

    const submitImageForm = async () => {
        toastLoadingId.current = ToastNotify.Loading('Đang lưu ảnh...');
        const file = await PublishSummaryService.createFile(
            cropperRef.current.getCanvas()?.toDataURL(),
            'logo'
        );
        PublishSummaryService.saveFiles(eventId, 'logo', file)
            .then(({ data }) => {
                ToastNotify.Update(toastLoadingId.current, 'Lưu ảnh thành công!');
                const event = data.data;
                // if (event?.is_public) {
                //     screenShot(event._id, event.url_slug);
                // }
            })
            .catch((error) =>
                ToastNotify.Update(
                    toastLoadingId.current,
                    error?.message || 'Đã có lỗi xảy ra khi lưu ảnh!',
                    'error'
                )
            )
            .finally(() => {
                setOpen(false);
                setOpenModal(false);
                setLoad(!load);
                toastLoadingId.current = null;
            });
    };
    return (
        <div className="mx-1.5 appearance-none justify-center rounded-md border-gray-300 bg-white px-2 pt-2 transition  hover:border-gray-400 focus:outline-none">
            <Cropper
                stencilProps={{
                    aspectRatio: 1,
                }}
                ref={cropperRef}
                style={{ height: '500px', width: '500px' }}
                className="cropper h-[500px]"
                src={image}
            />
            <div className="m-2 flex justify-center gap-2">
                <label className="flex cursor-pointer items-center gap-[6px] rounded-lg bg-blue-100 px-[16px] py-[8px] text-[16px] font-semibold text-blue-700 hover:bg-blue-200">
                    <input
                        className="hidden"
                        onChange={(event) => changeFile(event)}
                        type="file"
                        accept="image/*"
                    />
                    <FaPen /> Thay đổi
                </label>
                <button
                    className={`${
                        isLoadingBtn ? 'bg-blue-500' : 'bg-blue-600 hover:bg-blue-700 '
                    } flex items-center gap-[6px] rounded-lg  px-[16px] py-[8px] text-[16px] font-semibold text-white `}
                    onClick={() => submitHandle('avatarGroom')}
                    disabled={isLoadingBtn}
                >
                    {isLoadingBtn && <LoadingButtonIcon />}
                    {!isLoadingBtn && <FaSave />}
                    Lưu
                </button>
                <ModalLeavingPage
                    saveChanged={submitImageForm}
                    isOpen={isOpenModal}
                    confirmNavigation={() => {
                        setOpenModal(false);
                    }}
                    funToggle={() => {
                        setOpenModal(false);
                    }}
                />
            </div>
        </div>
    );
};

export default function LogoUploader({ eventId, isExpired, onDelete, isDelete, setDelete }) {
    const [openModal, setOpenModal] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [load, setLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImageToCrop(image);
            setOpenModal(true);
        });
        reader.readAsDataURL(val);
    };

    useEffect(() => {
        if (isDelete) {
            setImageToCrop(null);
            setDelete(false);
        }
    }, [isDelete]);

    useEffect(() => {
        setIsLoading(true);
        if (eventId) {
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    const imageSrc = data.form_data.logo;
                    if (imageSrc && imageSrc.length > 0) {
                        setImageToCrop(process.env.REACT_APP_SERVER_URL + imageSrc[0]);
                    } else {
                        setImageToCrop(null);
                    }
                })
                .finally(() => setIsLoading(false));
        }
    }, [load]);

    return (
        <>
            <div className="mb-4 scroll-mt-[100px] rounded-lg bg-white shadow">
                <div className="flex items-center justify-between border-b border-gray-200 p-4 ">
                    <p className="mb-0 text-[16px] font-bold text-gray-900">Logo sự kiện</p>
                </div>
                <div className="px-4 py-1">
                    {isLoading ? (
                        <div className="mx-auto h-40 w-40 rounded-md animate-pulse bg-gray-200">
                            {' '}
                        </div>
                    ) : (
                        <>
                            {imageToCrop && (
                                <ul className="mb-4 mt-2 flex justify-center divide-gray-200">
                                    <li className="group relative flex w-full min-w-48 max-w-48 aspect-square overflow-hidden rounded-md border p-2 backdrop-blur-none">
                                        <span>
                                            <img
                                                alt="Logo"
                                                className="absolute inset-0 m-auto box-border block h-0 max-h-full min-h-full w-0 min-w-full max-w-full object-contain p-0"
                                                src={imageToCrop}
                                            ></img>
                                        </span>
                                        {!isExpired && (
                                            <div
                                                onClick={() => onDelete()}
                                                className="absolute right-3 top-1 z-[10] w-[33px] cursor-pointer rounded-full border bg-slate-100 p-2 text-black shadow-lg hover:bg-gray-400"
                                            >
                                                <svg
                                                    width="15px"
                                                    height="15px"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill="none"
                                                        stroke="#000"
                                                        strokeWidth="2"
                                                        d="M3,3 L21,21 M3,21 L21,3"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                        <button
                                            type="button"
                                            disabled={isExpired}
                                            className="absolute inset-x-0 -top-0 flex h-full w-full flex-col items-center justify-center rounded-lg bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100"
                                            onClick={() => {
                                                setOpenModal(true);
                                            }}
                                        >
                                            <span className="fas fa-edit cursor-pointer text-3xl text-slate-100"></span>
                                        </button>
                                    </li>
                                </ul>
                            )}
                            {!imageToCrop && (
                                <label className="flex mx-auto h-40 w-full cursor-pointer  appearance-none justify-center rounded-lg  bg-white transition focus:outline-none">
                                    <span className="my-auto flex h-full justify-center space-x-2">
                                        <svg
                                            className="my-auto"
                                            width="32px"
                                            height="32px"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect x="0" fill="none" width="24" height="24" />
                                            <g>
                                                <path d="M23 4v2h-3v3h-2V6h-3V4h3V1h2v3h3zm-8.5 7c.828 0 1.5-.672 1.5-1.5S15.328 8 14.5 8 13 8.672 13 9.5s.672 1.5 1.5 1.5zm3.5 3.234l-.513-.57c-.794-.885-2.18-.885-2.976 0l-.655.73L9 9l-3 3.333V6h7V4H6c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-7h-2v3.234z" />
                                            </g>
                                        </svg>
                                        <span className="my-auto text-gray-700">
                                            Thêm ảnh (Giới hạn 10Mb)
                                        </span>
                                        <input
                                            type="file"
                                            className="hidden"
                                            disabled={isExpired}
                                            onChange={(event) => changeFile(event)}
                                            accept="image/*"
                                        />
                                    </span>
                                </label>
                            )}
                        </>
                    )}
                </div>
                <BaseModal
                    isOpen={openModal}
                    onClose={() => {
                        setOpenModal(false);
                        setLoad(!load);
                    }}
                    modalTitle="Chỉnh sửa Logo"
                    width="max-w-[700px]"
                >
                    <CropperModal
                        setOpen={setOpenModal}
                        defaultsrc={imageToCrop}
                        eventId={eventId}
                        load={load}
                        setLoad={setLoad}
                    />
                </BaseModal>
            </div>
        </>
    );
}

const screenShot = (eventId, eventUrlSlug) => {
    // Screen short
    const linkPage = envConstants.CLIENT_URL + eventUrlSlug;
    const linkThumbnail = envConstants.CLIENT_URL + 'thumbnail/' + eventUrlSlug;
    const location = 'events/' + eventId;
    const promises = [
        EventsService.getScreenshot(linkPage, location),
        EventsService.getScreenshot(linkThumbnail, location),
    ];
    Promise.all(promises)
        .then((responses) => {
            const arrPathData = responses.map(({ data }) => data.path);
            const [image, thumbnail] = arrPathData;
            const payload = {
                image: image,
                thumbnail: thumbnail,
            };
            return payload;
        })
        .then((formData) => EventsService.updateEventTemplate(eventId, formData))
        .catch((error) => console.log(error));
};
