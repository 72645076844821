/* eslint-disable */
import axios from './axios';

export const logIn = (email, password, guestId, tokenCaptcha) =>
    axios.post('/user/login', { email, password, guestId, tokenCaptcha });

export const authProviderLogIn = (authProvider, loginData, guestId) =>
    axios.post('/user/login', { authProvider, loginData, guestId });

export const signUp = (email, username, password, fullname, phone) =>
    axios.post('/user/signup', {
        email,
        user_name: username,
        password,
        ...(fullname && { fullname }),
        ...(phone && { phone }),
    });

export const changePassword = (payload = {}) => axios.post('/user/change-password', payload);

export const setPassword = (payload = {}) => axios.post('/user/set-password', payload);

export const verifyEmail = () => axios.post('/user/send-verify-email');

export const guestSignUp = () => axios.post('/user/signup-guest');
